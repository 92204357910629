import React, { forwardRef, useEffect, useRef, useState } from "react";
import Tooltip from '@mui/material/Tooltip';
import "./TooltipRenderer.css";

export const JsonTooltipRenderer = forwardRef(({value, valueFormatted}, ref) => {
  const [cellContent, setCellContent] = useState(value);
  const [tooltipContent, setTooltipContent] = useState("");
  const [showTooltip, setShowTooltip] = useState(true);
  const wrapper = useRef(null);

  const jsonPrettify = (json) => {
    return JSON.stringify(JSON.parse(json), null, 2);
  };
  
  const isValidJson = (str) => {
    try {
      return !!JSON.parse(str);
    } catch {
      return false;
    }
  };

  useEffect(() => {
    setCellContent(
      valueFormatted === null 
        ? value
        : valueFormatted
    );    
  }, [value, valueFormatted]);

  useEffect(() => {
    setTooltipContent(
      isValidJson(cellContent)
        ? <pre>{jsonPrettify(cellContent)}</pre>
        : <div className="tooltip-plain">{cellContent}</div>
    );
  }, [cellContent]);

  useEffect(() => {
    setShowTooltip(
      isValidJson(cellContent) 
      || wrapper.current && (wrapper.current.scrollWidth > wrapper.current.clientWidth)
    );
  }, [
    cellContent, 
    wrapper.current, 
    wrapper.current?.scrollWidth, 
    wrapper.current?.clientWidth
  ]);

  return (
    <div ref={wrapper} style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
      {showTooltip ? (
        <Tooltip title={tooltipContent} placement="left-start">
          <span>{cellContent}</span>
        </Tooltip>
      ) : (
        cellContent
      )}
    </div>
  );
});