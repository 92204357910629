import React, { useEffect, useState } from "react";
import { Grid, Button, Checkbox } from "@material-ui/core";
import Add from "@material-ui/icons/Add";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import './ReasonCodeDetails.css';

const ReasonCodeDetails = ({
  handleClose,
  handleSave,
  show,
  isEdit,
  data,
  handleValidation
}) => {

  const defaultData = {
    reasonId: null,
    reasonCode: "",
    reasonDefinitionInternal: "",
    reasonDefinitionExternal: "",
    severity: null,
    active: true,
    statusType: "",
  };
  const errorData = {
    // Error fields
    reasonCodeErr: "",
    reasonDefinitionInternalErr: "",
    reasonDefinitionExternalErr: "",
    severityErr: "",
    statusTypeErr: ""
  };
  const [errorFields, setErrorFields] = useState({ ...errorData });
  const [formData, setFormData] = useState({ ...defaultData });

  useEffect(() => {
    if (isEdit) {
      getReasonDetails();
    } else {
      setFormData({ ...defaultData });
    }
    setErrorFields({ ...errorData });
  }, [show]);

  const getReasonDetails = async () => {
    // const { data } = await axios.get(
    //   `${global.REACT_APP_API_ENDPOINT}/reasonCodes/getReasonCodeById/${reasonId}`, {});
    setFormData({ ...formData, ...data });
  };

  const handleSubmit = async () => {
    let {user} = JSON.parse(sessionStorage.getItem("passData"));
    let errorFieldsData = { ...errorData };
    let validationError = false;
    if (!handleValidation(formData, errorFieldsData)) {
      validationError = true;
    }
    if (!formData.reasonCode) {
      errorFieldsData.reasonCodeErr = "This field is required";
      validationError = true;
    }
    if (formData.severity == null) {
      errorFieldsData.severityErr = "This field is required";
      validationError = true;
    }
    if (!formData.reasonDefinitionExternal) {
      errorFieldsData.reasonDefinitionExternalErr = "This field is required";
      validationError = true;
    }
    if (!formData.reasonDefinitionInternal) {
      errorFieldsData.reasonDefinitionInternalErr = "This field is required";
      validationError = true;
    }
    if (!formData.statusType) {
      errorFieldsData.statusTypeErr = "This field is required";
      validationError = true;
    }
    setFormData({ ...formData });
    setErrorFields({ ...errorFieldsData });
    if (validationError) {
      return;
    }
    if (!formData.createdBy) {
      formData.createdBy = user;
    }
    console.log("handleSubmit", isEdit, formData)
    if (isEdit) {
      await axios.post(
        `${global.REACT_APP_API_ENDPOINT}/reasonCodes/updateReasonCode`, { ...formData, updatedBy: user });
      handleSave();
    } else {
      await axios.post(
        `${global.REACT_APP_API_ENDPOINT}/reasonCodes/saveReasonCode`, { ...formData, updatedBy: user });
      handleSave();
    }
  };

  const handleChange = (event) => {
    if (event.target.value == "") {
      errorFields[event.target.name + "Err"] = "This field is required"
    }
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
    setErrorFields({
      ...errorFields,
    })
  };

  return (
    <Modal
      show={show}
      size="lg"
      style={{
        background: "rgba(0, 0, 0, 0.5)",
      }}
      onHide={handleClose}
    >
      <Modal.Header className="the-modal-header" closeButton>
        {!isEdit ? (<Add
          style={{
            color: "#000000",
            marginRight: "5px",
            height: "25px",
            width: "25px",
          }}
        />) : (<></>)}
        <Modal.Title className="the-modal-title">
          {isEdit ? "Update Reason Details" : "Add Reason"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="reason-modal-body">
        <Grid container spacing={2}>
          <Grid item container spacing={2} xs={12} sm={12} md={6} className="reason-input-group">
            <Grid item xs={12} sm={12} md={12}>
              <div>Reason Code*</div>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <input
                type="text"
                id="reasonCode"
                name="reasonCode"
                className="form-control"
                value={formData.reasonCode}
                onChange={(event) => {
                  handleChange(event);
                }}
              />
              {errorFields.reasonCodeErr != "" ? (
                <p style={{ color: "red", margin: "0px" }}>{errorFields.reasonCodeErr}</p>
              ) : <></>}
            </Grid>
          </Grid>
          <Grid item container xs={12} sm={12} md={6} className="reason-input-group">
            <Grid item xs={12} sm={12} md={12}>
              <div>Severity*</div>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <input
                type="number"
                id="severity"
                name="severity"
                className="form-control"
                value={formData.severity == null ? "" : formData.severity}
                onChange={(event) => {
                  handleChange(event);
                }}
              />
              {errorFields.severityErr != "" ? (
                <p style={{ color: "red", margin: "0px" }}>{errorFields.severityErr}</p>
              ) : <></>}
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item container spacing={2} xs={12} sm={12} md={6} className="reason-input-group">
            <Grid item xs={12} sm={12} md={12}>
              <div>Reason Definition External*</div>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <textarea
                id="reasonDefinitionExternal"
                name="reasonDefinitionExternal"
                className="form-control"
                maxLength={500}
                value={formData.reasonDefinitionExternal}
                onChange={(event) => {
                  handleChange(event);
                }}
              />
              {errorFields.reasonDefinitionExternalErr != "" ? (
                <p style={{ color: "red", margin: "0px" }}>{errorFields.reasonDefinitionExternalErr}</p>
              ) : <></>}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={6} className="reason-input-group">
            <Grid item xs={12} sm={12} md={12}>
              <div>Reason Definition Internal*</div>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <textarea
                id="reasonDefinitionInternal"
                name="reasonDefinitionInternal"
                className="form-control"
                maxLength={500}
                value={formData.reasonDefinitionInternal}
                onChange={(event) => {
                  handleChange(event);
                }}
              />
              {errorFields.reasonDefinitionInternalErr != "" ? (
                <p style={{ color: "red", margin: "0px" }}>{errorFields.reasonDefinitionInternalErr}</p>
              ) : <></>}
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item container spacing={2} xs={12} sm={12} md={6} className="reason-input-group">
            <Grid item xs={12} sm={12} md={12}>
              <div>Status Type*</div>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <select
                required
                name="statusType"
                id="statusType"
                className="form-control"
                value={formData.statusType}
                onChange={event => {
                  handleChange(event);
                }}
              >
                {["", "Unavailable"].map(item => {
                  return (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  );
                })}
              </select>
              {errorFields.statusTypeErr != "" ? (
                <p style={{ color: "red", margin: "0px" }}>{errorFields.statusTypeErr}</p>
              ) : <></>}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={6} className="reason-input-group">
            <Grid item xs={6} sm={6} md={6}>
              <div>Is Active</div>
              <Checkbox checked={formData.active} color="primary" style={{
                color: "#1176B5"
              }} onChange={(event) => {
                formData.active = event.target.checked;
                setFormData({ ...formData });
              }} />
            </Grid>
          </Grid>
        </Grid>
        {isEdit ? (<div>Note: Any changes made will be reflected on existing Projects and Phases with this Reason Code.</div>) : (<></>)}
      </Modal.Body>
      <Modal.Footer className="the-modal-footer">
        <Button variant="contained" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" style={{
          backgroundColor: "rgb(17, 118, 181)",
          textTransform: "none"
        }}
          color="primary" onClick={handleSubmit}>
          {isEdit ? "Update" : "Save"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ReasonCodeDetails;
