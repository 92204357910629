import React, { useState } from "react";
import {
  Grid
} from "@material-ui/core";
import "./DeleteDuplicate.css";
import DeleteDuplicateForm from "./DeleteDuplicateForm";
import DeletePhaseDuplicate from "./DeletePhaseDuplicate";
import DuplicateSuccessPage from "./DuplicateSuccessPage";
import ReviewDuplicates from "./ReviewDuplicates"; 
import DuplicatePhaseForm from "./DuplicatePhaseForm";
import DuplicatePhaseList from "./DuplicatePhaseList";
import VerticalLinearStepper from "../VerticalLinearStepper/VerticalLinearStepper";
import axios from "axios";

const DeleteDuplicate = () => {
  const [stepCount, setStepCount] = React.useState(0);
  const incrementCounter = () => setStepCount(stepCount + 1);
  const decrementCounter = () => setStepCount(stepCount - 1);
  const [formData, setFormData] = useState({
    currentProject: "",
    duplicateProject: "",
    phases: [],
    certifications: [],
    updateBy: "",
    projectDetails: null,
    certificationList: [],
    formCertificationList: [],
    deleteProjectDetails: null,
    isConfirm: false,
    hasPhasesCertification: false,
    phaseDetails: [],
    currentPhaseCertifications: [],
    duplicatePhaseCertifications: [],
    currentPhasesForReview: [],
    duplicatePhasesForReview: [],
    event: null,
    prevPage: ''
  });
  React.useEffect(() => {
  }, []);

  const userRole = sessionStorage.getItem("role");

  const getPage = () => {
    switch (stepCount) {
        case 0:
          return ( <DeleteDuplicateForm  incrementCounter={incrementCounter}  setFormData={setFormData} formData={formData}/>);
        case 1:
          if((formData.duplicatePhases && formData.duplicatePhases!=='undefined' && formData.duplicatePhases.length>0 )==false) {
            if(formData.prevPage==='ReviewDuplicates') {
              decrementCounter();
            } else {
              incrementCounter();
            }
          } else {
            return ( <DeletePhaseDuplicate  decrementCounter={decrementCounter} incrementCounter={incrementCounter}  setFormData={setFormData} formData={formData}/>);
          }
        case 2:
          return ( <ReviewDuplicates decrementCounter={decrementCounter} incrementCounter={incrementCounter} formData={formData} setFormData={setFormData}/>);
        case 3:
          return ( <DuplicateSuccessPage  formData={formData}></DuplicateSuccessPage >)
    }
  };
  return (
    <>
      <Grid container>
        <Grid item xs={2} style={{ backgroundColor: "#F2F2F2" }}>
          { (formData.duplicatePhases && formData.duplicatePhases!=='undefined' && formData.duplicatePhases.length>0) ? 
        <VerticalLinearStepper
              steps={["Delete duplicate configuration", "Phase Duplicate", "Review & Save", "Final Confirmation"]}
              activeStep={stepCount}
        /> :
       
        <VerticalLinearStepper
              steps={["Delete duplicate configuration", "Review & Save", "Final Confirmation"]}
              activeStep={stepCount + 1}
        />
          }
        </Grid>
        <Grid
          container
          item
          xs={10}
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          style={{ padding: "20px", border: "solid 1px black" }}
        >
          {getPage()}
        </Grid>
      </Grid>
    </>
  );
};

export default DeleteDuplicate;
