import React, { useEffect, useState } from "react";
import moment from "moment";
import { SessionTimeOutModal } from "./SessionTimeOutModal";
import { useSelector } from "react-redux";
import { getLogoutUrl } from "../shared/constants/environment";
import axios from "axios";

export const SessionTimeOutHandler = () => {
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState("");
  const [isLogout, setLogout] = useState(false);
  let warnTimer = undefined;
  let logoutTimer = undefined;
  let warningTime = 25 * 60000;
  let logoutTime = 30 * 60000;

  const events = [
    "click",
    "load",
    "keydown",
    "mousemove",
    "mousedown",
    "scroll",
    "keypress",
  ];
  const userDetails = useSelector(
    (store) => store.getUserDetailsReducer.userDetails
  );
  const eventHandler = (eventType) => {
    if (!isLogout) {
      if (
        warnTimer &&
        logoutTimer &&
        window.location.pathname !== "/session-error-page"
      ) {
        startWarnTimer();
        startLogoutTimer();
      }
    }
  };

  useEffect(() => {
    addEvents();
    return () => {
      removeEvents();
      clearTimeout(warnTimer);
      clearTimeout(logoutTimer);
    };
  }, []);

  const startWarnTimer = () => {
    if (!isLogout) {
      if (warnTimer) {
        clearTimeout(warnTimer);
      }
      warnTimer = setTimeout(() => {
        let lastInteractionTime = sessionStorage.getItem("lastInteractionTime");
        const diff = moment.duration(
          moment().diff(moment(lastInteractionTime))
        );
        if (diff._milliseconds < warningTime) {
          startWarnTimer();
        } else {
          setShowModal(true);
        }
      }, 12000);
    } else {
      clearSessionStorage();
      clearTimeout(warnTimer);
    }
  };

  const startLogoutTimer = () => {
    if (!isLogout) {
      if (logoutTimer) {
        clearTimeout(logoutTimer);
      }
      logoutTimer = setTimeout(() => {
        let lastInteractionTime = sessionStorage.getItem("lastInteractionTime");
        const diff = moment.duration(
          moment().diff(moment(lastInteractionTime))
        );
        if (diff._milliseconds < logoutTime) {
          startLogoutTimer();
        } else {
          setShowModal(false);
          setLogout(true);
          window.location.replace("/session-error-page");
        }
      }, 12000);
    } else {
      clearSessionStorage();
      clearTimeout(logoutTimer);
    }
  };

  const addEvents = () => {
    events.forEach((eventName) => {
      window.addEventListener(eventName, eventHandler);
    });
    if (!isLogout && window.location.pathname !== "/session-error-page") {
      startWarnTimer();
      startLogoutTimer();
    }
  };

  const removeEvents = () => {
    events.forEach((eventName) => {
      window.removeEventListener(eventName, eventHandler);
    });
  };

  const handleContinueSession = () => {
    setShowModal(false);
    setLogout(false);
    axios
      .get(global.REACT_APP_API_ENDPOINT + "/condoMgr/health")
      .then((response) => {
        if (response.status == 200 || response.status == 201) {
          clearTimeout(warnTimer);
          sessionStorage.setItem("lastInteractionTime", moment());
        }
      });
  };

  const handleLogout = () => {
    removeEvents();
    clearTimeout(warnTimer);
    clearTimeout(logoutTimer);
    setLogout(true);
    setShowModal(false);
    logout();
  };

  const clearSessionStorage = () => {
    Object.keys(sessionStorage).forEach((key) =>
      sessionStorage.removeItem(key)
    );
  };

  const logout = () => {
    window.location.replace(getLogoutUrl(userDetails));
  };

  return (
    <div>
      <SessionTimeOutModal
        showModal={showModal}
        handleContinue={handleContinueSession}
        handleLogout={handleLogout}
      />
    </div>
  );
};
