import axios from "axios";
import { getEnvName } from "../../components/shared/constants/environment";
import { USER_DETAIL_RESULT, ROLE_DETAIL_RESULT } from "./ActionType";

export const setUserDatils = (details) => {
  return {
    type: USER_DETAIL_RESULT,
    payload: details,
  };
};

export const setRoleDetails = (details) => {
  return {
    type: ROLE_DETAIL_RESULT,
    payload: details,
  };
};

export async function fetchUserDetails() {
  if (getEnvName() === "local") {
    const mockUserData = {
      dealerOrgName: "Local Org",
      firstName: "Jason",
      lastName: "Smith",
      mail: "user_smith@fanniemae.com",
      telephoneNumber: "703-833-1111",
      user: "r0usct",
      sellerServicerCoNo: "26546",
      roles: [
        "SG-GFY-LE-CondoGo-ProjStd",
        "SG-GFY-LE-CondoGo-ProjStd-RO",
        "SG-GFY-LE-CondoGo-Adm",
        "SG-GFY-PROD-CondoGo-ProjStd-RO",
        "SG-GFY-PROD-CondoGo-ProjStd",
        "CONDO_Lender User",
        "CONDO_Lender Read-Only",
        "CONDO_Lender Admin User",
        "CONDOC_Lender User",
        "CONDOC_Lender Read-Only",
        "CONDOC_Lender Admin User",
        "CONDOCOR_Correspondent User",
        "CONDOCOR_Correspondent Admin User",
        "CONDOCOR_Correspondent Read Only",
        "CONDOCOC_Correspondent User",
        "CONDOCOC_Correspondent Admin User",
        "CONDOCOC_Correspondent Read Only"
      ],
      isExternalUser: "",
      isInternalUser: true,
    };
  
    // dispatch(getTranslatedRoleMap());
    sessionStorage.setItem("passData", JSON.stringify(mockUserData));

    return mockUserData;
  }

  try {
    const response = await axios.get(`/userdetails`);

    if (response.status == 200) {
      const userData = response.data.userDetails;
      userData.user = response.data.userDetails.uid;
      userData.roles = response.data.userDetails.groups;
      window['dynatraceUserName'] = userData.user;
      sessionStorage.setItem("passData", JSON.stringify(userData));
      return userData;
    }
  } catch (error) {
    window.location.replace(window.location.protocol +
      "//" + window.location.host + "/cgapi/?cdxRedirectPath=/");
  };
}

export function getUserDetail() {
  return async function (dispatch) {
    const userData = await fetchUserDetails();
    dispatch(setUserDatils(userData));
    dispatch(getTranslatedRoleMap());
  };
}

export async function fetchTranslatedRoleMap() {
  if (getEnvName() === "local") {
    const mockRoles = {
      "selectedRole": "SG-GFY-LE-CondoGo-ProjStd",
      "hashedRoleMap": {
        "SG-GFY-LE-CondoGo-ProjStd": "Project-Standards",
        "SG-GFY-LE-CondoGo-Adm": "FannieMae-Admin",
        "SG-GFY-LE-CondoGo-ProjStd-RO": "FannieMae-ReadOnly",
        "CONDOCOR_Correspondent User": "Correspondent User",
        "CONDO_Lender User": "Lender User",
        "CONDOCOR_Correspondent Admin User": "Correspondent Admin User",
        "CONDO_Lender Admin User": "Lender Admin User",
        "CONDOCOR_Correspondent Read Only": "Correspondent Read Only",
        "CONDO_Lender Read-Only": "Lender Read-Only",
      },
      "associatedLenderMap": {}
    }
    
    sessionStorage.setItem("roleData", JSON.stringify(mockRoles));
    if (!sessionStorage.getItem("role")) {
      sessionStorage.setItem("role", mockRoles.selectedRole);
    }

    return mockRoles;
  }

  try {
    const response = await axios.get(
      global.REACT_APP_API_ENDPOINT + `/condoMgr/translatedRoleMap`
    );

    if (response.status == 200) {
      sessionStorage.setItem("roleData", JSON.stringify(response.data));
      if (!sessionStorage.getItem("role")) {
        sessionStorage.setItem("role", response.data.selectedRole);
      }

      return response.data;
    }
  } catch (error) {
    console.error(error);
  }
}

export function getTranslatedRoleMap() {
  return async function (dispatch) {
    const roles = await fetchTranslatedRoleMap();
    dispatch(setRoleDetails(roles));
  };
}

export function isProjectRole() {
  let role = sessionStorage.getItem("role");

  if (
    role == "SG-GFY-LE-CondoGo-ProjStd" ||
    role == "SG-GFY-LE-CondoGo-ProjStd-RO" ||
    role == "SG-GFY-PROD-CondoGo-ProjStd" ||
    role == "SG-GFY-PROD-CondoGo-ProjStd-RO"
  ) {
    return true;
  }

  return false;
}

export function isProjectReadOnlyRole() {
  let role = sessionStorage.getItem("role");

  if (
    role == "SG-GFY-LE-CondoGo-ProjStd-RO" ||
    role == "SG-GFY-PROD-CondoGo-ProjStd-RO"
  ) {
    return true;
  }

  return false;
}

export function isProjectStandardRole() {
  let role = sessionStorage.getItem("role");
  if (
    role == "SG-GFY-LE-CondoGo-ProjStd" ||
    role == "SG-GFY-PROD-CondoGo-ProjStd"
  ) {
    return true;
  }

  return false;
}

export function isLenderRole() {
  let role = sessionStorage.getItem("role");
  if (
    role == "CONDO_Lender User" ||
    role == "CONDO_Lender Admin User" ||
    role == "CONDO_Lender Read-Only" ||
    role == "CONDOC_Lender User" ||
    role == "CONDOC_Lender Admin User" ||
    role == "CONDOC_Lender Read-Only"
  ) {
    return true;
  }

  return false;
}

export function isTPORole() {
  let role = sessionStorage.getItem("role");
  if (
    role == "CONDOCOR_Correspondent User" ||
    role == "CONDOCOR_Correspondent Admin User" ||
    role == "CONDOCOR_Correspondent Read Only" ||
    role == "CONDOCOC_Correspondent User" ||
    role == "CONDOCOC_Correspondent Admin User" ||
    role == "CONDOCOC_Correspondent Read Only"
  ) {
    return true;
  }

  return false;
}

export function isReadonlyRole() {
  let role = sessionStorage.getItem("role");

  if (
    role == "SG-GFY-LE-CondoGo-ProjStd-RO" ||
    role == "CONDO_Lender Read-Only" ||
    role == "CONDOCOR_Correspondent Read Only" ||
    role == "SG-GFY-PROD-CondoGo-ProjStd-RO"
  ) {
    return true;
  }

  return false;
}

export function isAdminRole() {
  let role = sessionStorage.getItem("role");
  if (
    role == "SG-GFY-LE-CondoGo-Adm" ||
    role == "SG-GFY-Prod-CondoGo-Adm"
  ) {
    return true;
  }

  return false;
}

export function isInternal() {
  if ( isProjectStandardRole() || isAdminRole() ) {
    return true;
  } else {
    return false;
  }
}
