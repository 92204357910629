import React, { forwardRef, useRef } from "react";
import { FaPen } from "react-icons/fa";
import Delete from "@material-ui/icons/Delete";
import "./RowControlsRenderer.css";

export const RowControlsRenderer = forwardRef((props, ref) => {
  const wrapper = useRef(null);

  return (
    <div ref={wrapper} className="row-controls">
      {props.canEdit ? (
        <button className="feed-button" onClick={() => props.onEditClick(props.data)}>
          <FaPen className="feed-icon edit-icon" />
          Edit
        </button>
      ) : (<></>)}

      {props.canDelete ? (
        <button className="feed-button" onClick={() => props.onDeleteClick(props.data)}>
          <Delete className="feed-icon delete-icon" />
          Delete
        </button>
      ) : (<></>)}
    </div>
  );
});