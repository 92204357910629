import { Button } from "@material-ui/core";
import RefreshIcon from '@material-ui/icons/Refresh';
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { JsonTooltipRenderer } from "../shared/components/AgGrid/JsonTooltipRenderer";
import { getDateFormatter, gridConfig } from "../shared/constants/AgGrid/GridConfig";
import { AuditTrailActions } from "../shared/constants/AuditTrailActions";
import mockData from "./mockData.json";
import "./AuditTrailList.css";

export default function AuditTrailList(props) {
  const lastDay = new Date();
  lastDay.setDate(lastDay.getDate() - 1);
  const [auditList, setAuditList] = useState([]);
  const [errorMinDate, setErrorMinDate] = useState("");
  const [errorMaxDate, setErrorMaxDate] = useState("");
  const [formData, setFormData] = useState({
    minDate: lastDay,
    maxDate: new Date(),
    feature: "",
  });

  const listOfFunctions = AuditTrailActions;

  useEffect(() => {
    getAuditTrails(false);
  }, []);

  const getAuditTrails = async (refresh) => {
    let inputParams;

    if (refresh) {
      const startTime = new Date();
      startTime.setDate(startTime.getDate() - 1);
      const endTime = new Date();

      setFormData({
        minDate: startTime,
        maxDate: new Date(),
        feature: "",
      });

      inputParams = {
        startTime: startTime.getTime(),
        endTime: endTime.getTime(),
      };

    } else {
      inputParams = {
        startTime: new Date(formData.minDate).getTime(),
        endTime: new Date(formData.maxDate).getTime(),
      };

      if (formData.feature != '') {
        inputParams['field'] = 'function';
        inputParams['value'] = formData.feature;
      }
    }
    try {
      let data;

      if (window.location.hostname === "localhost") {
        data = mockData;
      } else {
        const response = await axios.get(
          `${global.REACT_APP_API_ENDPOINT}/auditdata/getByCriteria`, 
          { params: inputParams }
        );
        data = response.data;
      }

      setAuditList(data);
      props.setGridData(data);
    } catch (err) {
      console.log(err);
    }
  };

  const convertDateFromUtcToLocal = (date) => {
    return moment.utc(date || "").local().format("YYYY-MM-DDTHH:mm");
  }

  const handleChange = (event) => {
    // console.log("handleChange", event.target.name, event.target.value)
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  function onSetStartDate(event) {
    if (event.target.value.length === 0) {
      setErrorMinDate("This field is required");
    } else {
      setErrorMinDate("");
    }
    handleChange(event);
  }

  function onSetEndDate(event) {
    if (event.target.value.length === 0) {
      setErrorExpiryDate("This field is required");
    } else if (moment(formData.minDate) > moment(event.target.value)) {
      setErrorMaxDate("Invalid Expiry Date");
    } else {
      setErrorMaxDate("");
    }

    handleChange(event);
  }

  function onFilterClick() {
    if (!errorMaxDate && !errorMinDate) {
      getAuditTrails(false);
    }
  }

  function onRefreshClick() {
    getAuditTrails(true);
  }

  const gridOptions = {
    ...gridConfig,

    columnDefs: [
      { headerName: "Action", field: "function", maxWidth: 160, filter: false, },
      {
        headerName: "Timestamp",
        field: "auditTimestamp",
        cellDataType: "date",
        filter: false,
        sort: "desc",
        maxWidth: 190,
      },
      { headerName: "User", field: "requestUser", maxWidth: 100, },
      { headerName: "Updates", field: "payload", },
    ],

    defaultColDef: {
      ...gridConfig.defaultColDef,
      cellRenderer: JsonTooltipRenderer,      
    },
    
    dataTypeDefinitions: {
      ...gridConfig.dataTypeDefinitions,
      date: getDateFormatter("MM/DD/YYYY hh:mm:ss A"),
    },
  };

  useEffect(() => {
    props.setGridOptions(gridOptions);
  }, []);

  return (
    <>
      <div className="filter-controls">
        <div className="cell">
          <div>Start Date*</div>
          <input
            type="datetime-local"
            id="minDate"
            name="minDate"
            className="form-control"
            value={convertDateFromUtcToLocal(formData.minDate)}
            onChange={onSetStartDate}
          />
          {errorMinDate != "" ? (
            <p style={{ color: "red", margin: "0px" }}>{errorMinDate}</p>
          ) : null}
        </div>
        <div className="cell">
          <div>End Date*</div>
          <input
            type="datetime-local"
            id="maxDate"
            name="maxDate"
            className="form-control"
            value={convertDateFromUtcToLocal(formData.maxDate)}
            onChange={onSetEndDate}
          />
          {errorMaxDate != "" ? (
            <p style={{ color: "red", margin: "0px" }}>{errorMaxDate}</p>
          ) : null}
        </div>
        <div className="cell">
          <div>Action</div>
          <select
            required
            name="feature"
            id="feature"
            className="form-control"
            value={formData.feature}
            onChange={handleChange}
          >
            <option key="" value="">All</option>
            {listOfFunctions?.length &&
              listOfFunctions.map(item => {
                return (
                  <option key={item} value={item}>
                    {item}
                  </option>
                );
              })}
          </select>
        </div>
        <div className="cell button-cell">
          <Button type="button" className="filter-button" onClick={onFilterClick}>
            Filter
          </Button>
        </div>
        <div className="cell button-cell">
          <Button type="submit" className="filter-button" onClick={onRefreshClick}>
            Refresh
            <RefreshIcon />
          </Button>
        </div>
      </div>
      { auditList?.length ? (
        <>          
          {/* <div style={{ height: "100%", width: "100%" }}>
            <div style={{ height: "438px", width: "100%", marginBottom: "20px" }}>
              <AgGridReact 
                {...gridOptions} 
                ref={gridRef} 
                rowData={auditList} 
              />
            </div>
          </div> */}
        </>
      ) : (
        <><p className="error"> No records found. </p></>
      )}
    </>
  );
}
