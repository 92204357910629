import { Button } from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import axios from "axios";
import React, { useEffect, useState } from "react";

export default function SmokeTest() {
  const [accessToken, setAccessToken] = useState("");

  const getAccessToken = async () => {
    try {
      const response = await axios.get(
        `${global.REACT_APP_API_ENDPOINT}/smoketest/getToken`,
        {
          headers: { Authorization: `Bearer YOUR_ACCESS_TOKEN`, },
        }
      );
      setAccessToken(response.data.access_token);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getAccessToken();
  }, []);

  return (
    <div style={{ minHeight: "742px" }}>
      <h5>Access token:</h5>
      <textarea style={{width: "400px", height: "200px"}} readOnly value={accessToken}></textarea>
      <div>
        <Button
          type="submit"
          style={{
            type: "button",
            color: "white",
            backgroundColor: "#1176B5",
            borderRadius: "4px",
            boxShadow: "0 0.4px 0.6px 0 rgba(0,0,0,0.37), 0 0.1px 0 0.4px #0A649D",
            height: "40px",
            width: "190px",
            marginTop: "20px",
            textTransform: "capitalize",
          }}
          onClick={getAccessToken}
        >
          Refresh
          <RefreshIcon />
        </Button>
      </div>
    </div>
  );
}
