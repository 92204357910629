import React from "react";
import { Grid } from "@material-ui/core";
import "./VerticalLinearStepperStyles.css";
import PropTypes from "prop-types";

const VerticalLinearStepper = ({ steps, activeStep }) => {
  const getStepClass = index => {
    if (index === activeStep) {
      return "fa fa-pencil active-step";
    } else if (index < activeStep) {
      return "fa fa-check completed-step";
    } else {
      return "fa fa-pencil";
    }
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <div
        className="d-flex justify-content-center"
        style={{ marginTop: "78px", flexDirection: "column" }}
      >
        {steps.map((label, index) => (
          <Grid
            key={index}
            item
            className="mb-5 step"
            xs={12}
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center"
            }}
          >
            <i className={getStepClass(index)} aria-hidden="true"></i>
            <p className="mb-0 ml-3">{label}</p>
          </Grid>
        ))}
      </div>
    </Grid>
  );
};

VerticalLinearStepper.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
  activeStep: PropTypes.number.isRequired
};

export default VerticalLinearStepper;
