import React, { useEffect, useState } from "react";
import { Grid, Button } from "@material-ui/core";
import "./DeleteDuplicate.css";
import axios from "axios";
import MultiselectTwoSide from "react-multiselect-two-sides";

const DeleteDuplicateForm = ({ incrementCounter, setFormData, formData }) => {
  const [errorCurrentProject, setErrorCurrentProject] = React.useState("");
  const [errorDuplicateProject, setErrorDuplicateProject] = React.useState("");
  const [certificationList, setCertificationList] = React.useState([]);
  const [selectedCertification, setSelectedCertification] = React.useState([]);
  const [currentCertification, setCurrentCertification] = React.useState([]);
  const [errorNextButton, setErrorNextButton] = React.useState("");
  const [loading, setLoading] = useState(true);
  const[showMessage, setShowMessage] = useState(false);
  React.useEffect(() => {
    formData.prevPage = 'DeleteDuplicate';
    if (formData.formCertificationList && formData.formCertificationList.length > 0) {
      setCertificationList(formData.formCertificationList);
      setSelectedCertification(formData.certifications);
      setCurrentCertification(formData.certificationList
        .filter((cert) => cert.projectId == formData.currentProject)
        .map((cert) => cert.certfcnId));
    }
  }, []);

  const handleChange = (event) => {
    let _formData = formData;
    _formData.event = event.target.name;
    setFormData({
      ..._formData,
      [event.target.name]: event.target.value,
    });
  };

  const onSubmitCalled = (e) => {
    if (formData.currentProject === '' || formData.duplicateProject === '' ||
      errorDuplicateProject != "" || errorCurrentProject != '' || formData.projectDetails === null) {
      setErrorNextButton("Please click on Fetch Details button");
      return;
    } else {
      setErrorNextButton("");
    }
    const passData = JSON.parse(sessionStorage.getItem("passData"));
    let _formData = formData;
    _formData.updateBy = passData?.user
    _formData.prevPage = 'DeleteDuplicate'
    setFormData(_formData);
    setTimeout(() => {
      if (formData.hasPhasesCertification > 0) {
        incrementCounter();
      } else {
        incrementCounter();
        incrementCounter();
      }
    }, 300);
  };

  const getProjectDetails = async (e) => {
    try {
      let _formData = formData;
      _formData.event = 'FetchDetails';
      setLoading(true);

      const currentProject = await axios.get(global.REACT_APP_API_ENDPOINT +
        `/condoMgr/getProjectDetailsByProjectId/` +
        formData.currentProject);
      const duplicateProject = await axios.get(global.REACT_APP_API_ENDPOINT +
        `/condoMgr/getProjectDetailsByProjectId/` +
        formData.duplicateProject);
      
      if (currentProject.status === 200 && duplicateProject.status === 200) {
        const currentProjectDetails = currentProject.data;
        const duplicateProjectDetails = duplicateProject.data;
        if (currentProjectDetails === `Cannot find project:${formData.currentProject}`) {
          setErrorCurrentProject(`Cannot find project:${formData.currentProject}`);
        } else {
          _formData.projectDetails = currentProjectDetails.projectProfile;
        }
        if (duplicateProjectDetails === `Cannot find project:${formData.duplicateProject}`) {
          setErrorDuplicateProject(`Cannot find project:${formData.duplicateProject}`);
        } else {
          _formData.deleteProjectDetails = duplicateProjectDetails.projectProfile;
          if (duplicateProjectDetails.phaseDataList.length > 0) {
            _formData.hasPhasesCertification = duplicateProjectDetails.phaseDataList.filter(p => p.certificationCount > 0).length > 0;

            constructPhaseObject(currentProjectDetails, duplicateProjectDetails);
          } else {
            _formData.duplicatePhases = [];
            getProjectCertification();        
          }

        }
       setFormData(_formData);
       setLoading(false);
      } 
      setFormData(_formData);
      setLoading(false);
    } catch (err) {
      setErrorCurrentProject('Error');
      setErrorDuplicateProject('Error');
    }

  }
  const constructPhaseObject = async (currentProjectDetails, duplicateProjectDetails) => {
    let _formData = formData;

    if (duplicateProjectDetails !== undefined) {
      _formData.duplicatePhases = duplicateProjectDetails.phaseDataList;
      _formData.duplicatePhasesForReview = _formData.duplicatePhases;
    }
    else {
    }
    if (currentProjectDetails !== undefined) {
      _formData.targetPhases = currentProjectDetails.phaseDataList;
      _formData.targetPhasesForReview = _formData.targetPhases;
    }
    else {
      console.log('setting target phases statically..');
    }

    _formData.duplicatePhaseForm = [];
    if (_formData.duplicatePhases && _formData.duplicatePhases.length > 0) {

      _formData.duplicatePhases.map(async (c, index) => {

        const duplicatePhaseCertifications = await axios.get(global.REACT_APP_API_ENDPOINT +
          `/condoMgr/getAllLenderCertificationsForProject/` +
          currentProjectDetails.projectId);

        const targetPhaseCertification = duplicatePhaseCertifications;

        if (targetPhaseCertification.status === 200 && duplicatePhaseCertifications.status === 200) {
          var certList = [];
          duplicatePhaseCertifications.data.map((d) => {
            if (d.phaseId === c.phaseId) {
              certList.push(d.certfcnId);
            }
          });
        }

        c.certificationList = certList;

        _formData.duplicatePhaseForm.push({
          "phaseId": c.phaseId,
          "phaseNumber": c.phaseNumber,
          "entirePhase": false,
          "targetPhase": null,
          "certifications": certList,
          "certificationList": certList,
        })
        _formData.phasedropdownOption = [];
        _formData.targetPhases.map(c => {
          _formData.phasedropdownOption.push({ 'label': c.phaseId + " " + c.phaseNumber, 'value': c.phaseId })
        });
      })

    } else {
      console.log(_formData);
    }
    getProjectCertification();
  }

  const getPhaseCertification = (currentAllCerts, duplicateAllCerts) => {
    
      let _formData = formData;
      _formData.currentPhaseCertifications = [];

      if (formData.targetPhases !== undefined) {
        formData.targetPhases.map( p => {

          let targetPhaseCertifications = [];
          targetPhaseCertifications = currentAllCerts;

          if (targetPhaseCertifications.length > 0) {
            targetPhaseCertifications.forEach((currentPhase) => {
            
              if (currentPhase.phaseId == p.phaseId) {
                _formData.currentPhaseCertifications.push(currentPhase);
              }
            })
          }
        })
      }

      _formData.duplicatePhaseCertifications = [];
      if (formData.duplicatePhases !== undefined) {
        formData.duplicatePhases.map( p => {
          let duplicatePhaseCertifications = [];
          duplicatePhaseCertifications = duplicateAllCerts;
          if (duplicatePhaseCertifications.length > 0) {
            duplicatePhaseCertifications.forEach((duplicatePhase) => {
              if (duplicatePhase.phaseId == p.phaseId) {
              _formData.duplicatePhaseCertifications.push(duplicatePhase);
              }
            })            
            setFormData(_formData);
          }
        })
      } 
  }

  const getProjectCertification = async () => {
    try {
      let projectPhaseCert = [];
      const currentProject = await axios.get(global.REACT_APP_API_ENDPOINT +
        `/condoMgr/getAllLenderCertificationsForProject/` +
        formData.currentProject);
      const duplicateProject = await axios.get(global.REACT_APP_API_ENDPOINT +
        `/condoMgr/getAllLenderCertificationsForProject/` +
        formData.duplicateProject);

      if (currentProject.status === 200 && duplicateProject.status === 200) {
        const currentProjectCertification = currentProject.data.filter((cert) => !cert.phaseId);
        const duplicateProjectCertification = duplicateProject.data.filter((cert) => !cert.phaseId);
        if (currentProjectCertification.length > 0) {
          let _currentCert = getCertificationDetails(currentProjectCertification, formData.currentProject);
          _currentCert = _currentCert.map(c => {
            c.disabled = true;
            return c;
          })
          projectPhaseCert = [...projectPhaseCert, ..._currentCert];
          const _formData = formData;
          _formData.certifications = _currentCert.map(c => c.value);
          setFormData(_formData);
          setSelectedCertification(_currentCert.map(c => c.value));
          setCurrentCertification(_currentCert.map(c => c.value));
        }
        if (duplicateProjectCertification.length > 0) {
          let _duplicateCerts = getCertificationDetails(duplicateProjectCertification, formData.duplicateProject);
          projectPhaseCert = [...projectPhaseCert, ..._duplicateCerts];

        }
        setCertificationList(projectPhaseCert);
        const _formData = formData;
        _formData.certificationList = [...duplicateProjectCertification, ...currentProjectCertification];
        _formData.formCertificationList = projectPhaseCert;
        setFormData(_formData);
        getPhaseCertification(currentProject.data,duplicateProject.data);

      }
    } catch (err) {
      console.log(err);
    }

  }

  const getCertificationDetails = (list, projectId) => {
    let tempList = [];
    list.map((cert) => {
      tempList.push({
        value: cert.certfcnId,
        name: "Project - " + projectId + " " + " #" + cert.certfcnId + ' ' + cert.lenderName + ' ' + cert.lenderCertDate
      });
    });

    return tempList;
  }

  const handleCertificationChange = (value) => {
    let _formData = formData;
    if (value.length == 0) {
      value.push(...currentCertification);
    }
    _formData.certifications = value;
    setFormData(_formData);

    setSelectedCertification(value);
  }

  const messageShowingConditon = (formData.duplicatePhases !== undefined && formData.duplicatePhases.length > 0) || (
    formData.duplicateProject != undefined && formData.duplicateProject.length == 0) || (formData.event !== 'FetchDetails') || (loading === true);

  return (
    <Grid item xs={12}>
      <div className="delete-duplicate-page">
        <Grid item xs={12}>
          <div className="section-title">
            Step1: Delete duplicate configuration
          </div>
        </Grid>
        <Grid container spacing={2} className="dialoguClass">
          <Grid item xs={12} sm={12} md={12}>
            <div>Current Project*</div>
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <input
              type="number"
              id="currentProject"
              name="currentProject"
              className="form-control"
              value={formData.currentProject}
              onChange={(event) => {
                setLoading(true);
                if (event.target.value.length === 0) {
                  setErrorCurrentProject("This field is required");
                } else {
                  setErrorCurrentProject("");
                }
                handleChange(event);
              }}
            />

          </Grid>
        </Grid>
        <Grid container spacing={2} className="dialoguClass">
          <Grid item xs={12} sm={12} md={12}>
            <div>Duplicate Project*</div>
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <input
              type="number"
              id="duplicateProject"
              name="duplicateProject"
              className="form-control"
              value={formData.duplicateProject}
              onChange={(event) => {
                if (event.target.value.length === 0) {
                  setErrorDuplicateProject("This field is required");
                } else {
                  setErrorDuplicateProject("");
                }
                handleChange(event);
              }}
            />

          </Grid>

        </Grid>

        {(messageShowingConditon) ? <div></div> :
    
        <Grid item xs={6} sm={6} md={6}>         
           <div> <div>&nbsp;&nbsp;&nbsp;&nbsp;No phases in duplicate project</div></div> 
        </Grid>
         }
        <Grid container className="dialoguClass">
          <Grid item xs={12} sm={12} md={12} style={{ padding: "8px" }}>
            <Button
              type="button"
              disabled={(formData.currentProject === '' || formData.duplicateProject === '' || errorDuplicateProject != "" || errorCurrentProject != '')}
              style={{
                type: "button",
                color: "white",
                backgroundColor: "#1176B5",
                borderRadius: "4px",
                boxShadow:
                  "0 0.4px 0.6px 0 rgba(0,0,0,0.37), 0 0.1px 0 0.4px #0A649D",
                height: "40px",
                width: "190px",
                textTransform: "capitalize",
              }}

              onClick={  
                (e) => {
                  setShowMessage(messageShowingConditon)
                  if (errorDuplicateProject === '' && errorCurrentProject === '')
                    setCertificationList([]);
                  setSelectedCertification([]);
                  setCurrentCertification([]);
                  getProjectDetails(e)
                  setErrorNextButton("");               
                }
              }
            >
              Fetch Details
            </Button>
          </Grid>
        </Grid>
        {certificationList?.length > 0 &&
          <Grid container spacing={2} className="dialoguClass">
            <Grid item xs={12} sm={12} md={12}>
              <div>Certifications*</div>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <MultiselectTwoSide
                options={certificationList}
                value={selectedCertification}
                className="msts_theme_example"
                onChange={(e) => {
                  handleCertificationChange(e)
                }
                }
                availableHeader="Duplicate Project Certifications"
                selectedHeader="Current Project Certifications"
                labelKey="name"
                showControls
              />
              {errorDuplicateProject != "" ? (
                <p style={{ color: "red", margin: "0px" }}>{errorDuplicateProject}</p>
              ) : null}
            </Grid>
          </Grid>
        }
        {
          <Grid container spacing={2} className="dialoguClass" style={{ paddingRight: '25px' }}>
            <Grid item xs={12} sm={12} md={12}>
              <Grid container justifyContent="flex-end">
                <Button
                  type="submit"
                  style={{
                    color: "#fff",
                    backgroundColor: "rgb(17, 118, 181)",
                    borderRadius: "4px",
                    boxShadow:
                      "rgb(0 0 0 / 37%) 0px 0.4px 0.6px 0px, rgb(10 100 157) 0px 0.1px 0px 0.4px",
                    height: "40px",
                    width: "150px",
                    textTransform: "capitalize"
                  }}
                  onSubmit={e => onSubmitCalled(e)}
                  onClick={e => onSubmitCalled(e)}
                  form="addProjectForm"
                >
                  Next
                </Button>

              </Grid>
              <Grid>


                <div style={{
                  textAlign: "right",
                  color: "red"
                }}>
                  {errorNextButton}
                </div>



              </Grid>
            </Grid>
          </Grid>
        }
      </div >
    </Grid >
  );
};

export default DeleteDuplicateForm;
