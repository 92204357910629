import React, { Component } from "react";
import axios from "axios";
import "../Profile/Profile.css";
import "./Project.css";
import ContextAwareToggle from "../Profile/ContextAwareToggle";
import { Link } from "react-router-dom";
import { Nav, Navbar, Accordion, Card } from "react-bootstrap";
import {
  FaChevronLeft,
  FaPen,
  FaPaperPlane,
  FaSave,
  FaEdit,
  FaCheckCircle,
} from "react-icons/fa";
import { IoMdDownload } from "react-icons/io";
import { MdEdit } from "react-icons/md";
import { MdFormatLineSpacing } from "react-icons/md";
import Profile from "../Profile/Profile";
import Comments from "../Comments/Comments";
import LenderList from "../LenderList/LenderList";
import Pdf from "../Pdf/Pdf";
import DropzoneModal from "../Dropzone/DropzoneModal";
import PhaseList from "../PhaseList/PhaseList";
import PersList from "../PersList/PersList";
import LenderCertificationList from "../LenderCertificationList/LenderCertificationList";
import $ from "jquery";
import ErrorPage from "../../error-page";
import {
  isProjectRole,
  isLenderRole,
  isReadonlyRole,
  isTPORole,
  isProjectStandardRole,
  isInternal,
  isAdminRole,
  fetchUserDetails,
} from "../../redux/actions/userAction";

import {
  Field as ProjectField,
  DatePicker as ProjectDatePicker,
  UpdatedField as UpdatedProjectField,
} from "../Pers-atoms";
import ProjectRestrictions from "../Restrictions/Project/RestrictionsProject";
import { Form } from "formik";
import { Grid, Button, Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Add from "@material-ui/icons/Add";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import HomeIcon from "@material-ui/icons/Home";
import RemoveIcon from "@material-ui/icons/Remove";
import InfoIcon from "@material-ui/icons/Info";
import ProjectPreRestrictions from "./ProjectPreRestrictions";
import AddModalRest from "../AddNewPersDecision/pages/add-modal-rest";
import CannedRestrication from "../AddNewPersDecision/pages/canned-restriction";
import ProjectPreRestrictionsModal from "./ProjectPreRestrictionsModal";
import ContainerCModal from "./ContainerCModal";
import FannieMaeIcon from "../../images/icons/Shape@1x.svg";
import FeedbackRemoveIcon from "../../images/icons/feedback-remove@1x.svg";
import SuccessIcon from "../../images/icons/Success@1x.svg";
import TimerIcon from "../../images/icons/timer@1x.svg";
import WarningIcon from "../../images/icons/Warning@1x.svg";
import EditIcon from "../../images/icons/edit@1x.svg";
import DeleteModal from "./delete-modal";
import ConfirmModal from "./confirm-modal";
import CertifyModal from "./certify-modal";
import ApprovalConditions from "../ApprovalConditions/ApprovalConditions";
import { RadioGroup, FormControlLabel } from "@material-ui/core";
import RadioInput from "../RadioInput/RadioInput";
import FmActivityFeed from "../FmActivityFeed/FmActivityFeed";
import Success1Icon from "../../images/icons/Success-1@1x.svg";
import Warning1Icon from "../../images/icons/Warning-1@1x.svg";
import LayersBlackIcon from "../../images/layers_black_24dp@1x.svg";
import CriticalIcon from "../../images/Critical@2x.svg";
import Group17Icon from "../../images/Group 17.svg";
import Group11Icon from "../../images/Group 11@1x.svg";
import Group34Icon from "../../images/Group-34.svg";
import AddCircleIcon from "../../images/add_circle@1x.svg";
import NewCondoIcon from "../../images/newcondo-image.png";
import EstCondoIcon from "../../images/established-image.png";
import ApartmentIcon from "../../images/apartment_black_24dp-1@2x.svg";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import { getDays, getGoogleAddressFields , filterProjectRestrictionForProject, filterProjectConditionsForProject} from "../shared/services/SharedService";
import CommentModal from "../Comments/CommentModal";

let a = [];

const CustomTooltip = withStyles({
  tooltip: {
    color: "black",
    backgroundColor: "white",
    border: "1px solid #E6E8ED",
  },
  arrow: {
    color: "white",
    "&:before": {
      border: "1px solid #E6E8ED",
    },
  },
})(Tooltip);

const CustomWidthTooltip = withStyles({
  tooltip: {
    color: "black",
    backgroundColor: "white",
    border: "1px solid #E6E8ED",
    maxWidth: 336,
  },
  arrow: {
    color: "white",
    "&:before": {
      border: "1px solid #E6E8ED",
    },
  },
})(Tooltip);
class Project extends Component {
  
  constructor(props) {
    const passData = JSON.parse(sessionStorage.getItem("passData"));
    super(props);
    this.state = {
      project: [],
      projects: {},
      projectId: "",
      phaseId: null,
      persId: null,
      phaseList: [],
      persList: [],
      persDataList: [],
      projectRestrictions: [],
      projectName: "",
      projectPersStatus: "",
      userName: passData && passData.user,
      comments: "",
      altProjectName: "",
      projectTypeStatus: "",
      newProjectType: "",
      city: "",
      hoaName: "",
      hoaTaxId: "",
      masterAssociationName: "",
      projectStyle: "",
      editProjectType: "",
      state: "",
      street: "",
      styleComments: "",
      unitCount: "",
      zip5: "",
      zipPlus4: "",
      commentCharCount: 0,

      collapseKey: "1",
      isEdit: false,
      projectInfo: {},
      yesNO: [
        { key: "true", value: "Yes" },
        { key: "false", value: "No" },
      ],
      unitType: [
        { key: "Attached", value: "Attached" },
        { key: "Detached", value: "Detached" },
        { key: "Combination", value: "Combination" },
      ],
      lenderData: {},
      styles: [],
      states: [],
      getLenders: [],
      legalNameError: "",
      totalUnitError: "",
      addressError: "",
      stateError: "",
      cityerror: "",
      zipError: "",
      projectError: "",
      otherCommentError: "",
      hoaError: "",
      oldData: [],
      apiErrProject: "",
      internalErrProject: false,

      apiErrLenderProj: "",
      internalErrLenderProj: false,

      apiErrComment: "",
      internalErrComment: false,

      apiErrRestrictions: "",
      internalErrRestrictions: false,

      showAdd: false,
      showHideInfo: true,
      showHideRestrictions: false,
      activeTab: "pre",
      restrictionErr: "",
      formData: [],
      showContainerCModal: false,
      lenderCertificationList: [],
      certificationCount: null,
      showDeleteModal: false,
      approvalConditions: [],
      totalPhasesCount: null,
      approvedPhaseCount: null,
      fmActivity: [],
      disablePostButton: false,
      showConfirmModal: false,
      hasNewComments: false,
      hasNewPdfs: false,
    };
    this.submitHandler = this.submitHandler.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.collapseAll = this.collapseAll.bind(this);
    this.hideComponent = this.hideComponent.bind(this);
    this.inputRef = React.createRef();
  }

  updateAddressField(place) {
    let address = getGoogleAddressFields(place)
    const updatedInfo = this.state.projectInfo;
    updatedInfo.street = address.street;
    updatedInfo.city = address.city;
    updatedInfo.state = address.state;
    updatedInfo.zip5 = address.zip;
    this.setState({
      projectInfo: updatedInfo,
    });
  }

  componentDidMount() {
    this.initializeAutoComplete();
    this.fetchStyles();
    this.fetchState();
    const passData = JSON.parse(sessionStorage.getItem("passData"));
    let role = sessionStorage.getItem("role");
    if (role) {
      this.init(passData);
    } else {
      this.getUserDetails();
    }

    this.watchForScroll();
  }

  componentWillUnmount() {
    this.observers.forEach(obs => obs.disconnect());
  }

  initializeAutoComplete() {
    if (this.inputRef?.current) {
      const input = this.inputRef.current;
      const autocomplete = new window.google.maps.places.Autocomplete(input, {
        componentRestrictions: { country: 'US' },
        fields: ["address_components"],
        types: ['address']
      });
  
      autocomplete?.addListener('place_changed', () => {
        const place = autocomplete?.getPlace();
        if (place) {
          this.updateAddressField(place)
        }
      });
    }
  }

  async init(passData) {
    await Promise.all([
      this.getProjectDetailsById(passData),
      this.lenderViewProject(passData),
      this.getCertificationDetails(passData),
      this.getPreCannedRestriction(passData),
      this.getDeliveryRestrictions(passData),
      this.fetchShowDeleteBox(passData),
      this.getComments(passData),
      this.getReasonCodes(),
      this.fetchFMActivity()
    ]);
  }

  observers = [];

  watchForScroll = () => {
    const createObserver = (element, callback) => {
      if (!element) {
        return;
      }
      
      const observer = new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting) {
          callback();
        }
      });

      observer.observe(element);
      this.observers.push(observer);
    };

    const commentsHeader = document.getElementById('commentsHeader');
    const pdfsHeader = document.getElementById('pdfsHeader');

    createObserver(commentsHeader, () => this.state.hasNewComments && this.markCommentsRead());
    createObserver(pdfsHeader, () => this.state.hasNewPdfs && this.markPdfsRead());
  };

  async getUserDetails() {    
    const userData = await fetchUserDetails();    
    this.init(userData);
  }

  async getProjectDetailsById(passData) {
    try {
      const { data: project } = await axios.get(
        global.REACT_APP_API_ENDPOINT +
        `/condoMgr/getProjectDetailsByProjectId/` +
        this.props.match.params.project_id
      );
      if (project === `Cannot find project:${this.props.match.params.project_id}`) {
        console.warn(project);
        return;
      }
      this.setState({
        project,
      });
      this.setState({
        oldData: project,
        projectInfo: project.projectProfile,
      });
      this.setState({
        editProjectType: project.projectProfile.newProjectType,
      });
      this.addProject();
      localStorage.setItem(
        "project" + [project.projectId],
        JSON.stringify(this.state.project.projectProfile)
      );
    } catch (err) {
      console.log(err);
    }
  }

  async getComments(passData) {
    const userName = passData.user;
    const projectId = this.props.match.params.project_id;
    if (isProjectStandardRole() || isReadonlyRole() || isLenderRole() || isTPORole() || isAdminRole()) {
      try {        
        let { data: getComments } = await axios.get(
          global.REACT_APP_API_ENDPOINT +
          `/condoMgr/getCommentsByProject/` +
          projectId + 
          '/' +
          userName
        );   
                
        getComments = isLenderRole() || isTPORole() ? getComments?.filter(c => c.showExternal) : getComments;
        
        this.setState({
          getComments,
          hasNewComments: getComments?.some(comment => !comment.isRead)
        });
      } catch (err) {
        console.log(err);
      }
    }
    if (isProjectStandardRole() || isReadonlyRole()) {
      try {
        let projectId = this.state.projectId;
        const { data: getLenders } = await axios.get(
          global.REACT_APP_API_ENDPOINT +
          `/lender/viewProjectByLenderList/` +
          this.props.match.params.project_id
        );
        this.setState({
          getLenders,
        });
        console.log("LENDER LIST", this.state.getLenders);
      } catch (err) {
        console.log(err);
      }
      try {
        let projectId = this.state.projectId;
        let phaseId = this.state.phaseId;
        const { data: getPdf } = await axios.get(
          global.REACT_APP_API_ENDPOINT +
          `/docStorage/listDocsByProjectIdExcludingPersId/` +
          this.props.match.params.project_id +
          '/' +
          userName
        );
        this.setState({
          getPdf,
          hasNewPdfs: getPdf?.some(pdf => !pdf.isRead)
        });
        console.log('pdfs', this.state.getPdf);
      } catch (err) {
        console.log(err);
      }
    }
  }

  async lenderViewProject(passData) {
    if (isLenderRole() || isTPORole()) {
      try {
        const lenderId = sessionStorage.getItem("lenderId") || passData.sellerServicerCoNo || "12346";
        let id = this.props.match.params.project_id;
        const { data: lenderData } = await axios.get(
          global.REACT_APP_API_ENDPOINT +
          `/lender/lenderViewProject/` +
          id +
          "/" +
          lenderId
        );
        this.setState({
          lenderData,
        });
        // if ender role pull phases from LenderViewProject
        if (isLenderRole() || isTPORole()) {
          this.setState({
            phaseList: lenderData.phaseDataList,
          });
        }
      } catch (err) {
        console.log(err);
      }
    }
  }

  async getDeliveryRestrictions(passData) {
    try {
      let id = this.props.match.params.project_id;
      let data;
      if (isLenderRole() || isTPORole()) {
        const lenderId = sessionStorage.getItem("lenderId") || passData.sellerServicerCoNo || "12346";
        data = await axios.get(
          global.REACT_APP_API_ENDPOINT +
          `/condoMgr/getExternalPersRestrictionConditions/` +
          id +
          `/` +
          lenderId
        );
      } else {
        data = await axios.get(
          global.REACT_APP_API_ENDPOINT +
          `/condoMgr/getPersRestrictionConditions/` +
          id
        );
      }
      let projectRestrictions = [];
      let approvalConditions = [];
      console.log(data);
      projectRestrictions = filterProjectRestrictionForProject(data.data.restrictionsConditionsList,isLenderRole() || isTPORole());
      approvalConditions = filterProjectConditionsForProject(data.data.restrictionsConditionsList);
      this.setState({
        projectRestrictions,
        approvalConditions
      });
    } catch (err) {
      console.log(err);
    }
  }

  async getPreCannedRestriction(passData) {
    if (isProjectStandardRole()) {
      try {
        let id = this.props.match.params.project_id;
        const { data: formData } = await axios.get(
          global.REACT_APP_API_ENDPOINT +
          `/delvyRestrictions/editProjectManualDeliveryRestrictions/` +
          id
        );
        formData.preCannedDR = formData.preCannedDR
          .sort((a, b) => {
            const item1 = a["restrictionsName"].split(" ")[1];
            const item2 = b["restrictionsName"].split(" ")[1];
            return parseInt(item2) < parseInt(item1) ? 1 : -1;
          })
          .map((restriction) => restriction);
        this.setState({
          formData,
        });
      } catch (err) {
        console.log(err);
      }
    }
  }

  async getCertificationDetails(passData) {
    let id = this.props.match.params.project_id;
    if (isLenderRole() || isTPORole()) {
      try {
        const lenderId = sessionStorage.getItem("lenderId") || passData.sellerServicerCoNo || "12346";
        const { data: lenderCertificationList } = await axios.get(
          global.REACT_APP_API_ENDPOINT +
          `/lender/lenderViewHistoricalCertificationsList/` +
          id +
          `/` +
          lenderId
        );
        this.setState({
          lenderCertificationList,
        });
      } catch (err) {
        console.log(err);
      }
    } else if (isProjectRole()) {
      try {
        const { data: lenderCertificationList } = await axios.get(
          global.REACT_APP_API_ENDPOINT +
          `/condoMgr/getActiveLenderCertificationsForProject/` +
          id
        );
        this.setState({
          lenderCertificationList,
        });
        this.setState({
          certificationCount: lenderCertificationList
            ? lenderCertificationList.length.toString()
            : 0,
        });
      } catch (err) {
        console.log(err);
      }
    }
  }

  async fetchFMActivity() {
    try {
      const { data } = await axios.get(
        global.REACT_APP_API_ENDPOINT +
        `/activityFeed/getFnmaReviewStatusActivityFeedForProject/` + this.props.match.params.project_id
      );
      this.setState({ fmActivity: data.activityFeedFnmaStatusDTO || [] });
    } catch (error) {
      throw Error(error);
    }
  }

  fetchStyles() {
    const response = axios
      .get(
        global.REACT_APP_API_ENDPOINT + `/condoMgr/lookUpValues/BUILDING-TYPES`
      )
      .then((response) => {
        const stylesResponse = response.data;
        const styles = [];
        for (const style in stylesResponse) {
          styles.push({
            key: stylesResponse[style],
            value: stylesResponse[style],
          });
        }
        styles.unshift({ key: "", value: "Select Style" });
        this.setState({
          styles: styles,
        });
      })

      .catch((error) => {
        throw Error(error);
      });
  }

  fetchState() {
    axios
      .get(global.REACT_APP_API_ENDPOINT + `/condoMgr/lookUpValues/states`)
      .then((response) => {
        const statesResponse = response.data;
        const states = [];
        for (const state in statesResponse) {
          states.push({
            key: statesResponse[state],
            value: statesResponse[state],
          });
        }
        states.unshift({ key: "", value: "Select State" });
        // dispatch(statesService(states));
        this.setState({
          states: states,
        });
      })

      .catch((error) => {
        throw Error(error);
      });
  }

  async fetchShowDeleteBox() {
    if (isProjectStandardRole()) {
      const project_id = this.props.match.params.project_id;
      try {
        const { data } = await axios.get(
          `${global.REACT_APP_API_ENDPOINT}/condoMgr/showDeleteButtonForProject/${project_id}`
        );
        this.setState({
          showDeleteModal: data || false,
        });
      } catch (e) {
        console.log(e);
      }
    }
  }

  async getReasonCodes() {
    try {
      const { data } = await axios.get(global.REACT_APP_API_ENDPOINT + `/reasonCodes/getAllReasonCodes`);
      // console.log(data);
      if ( data ) {
        this.setState({
          reasonCodes : data.sort((a, b) => a.reasonCode.localeCompare(b.reasonCode))
        });
      }
    } catch (err) {
      console.log(err);
    }
  };


  toggleEdit = (flag) => {
    this.setState({
      isEdit: flag,
    });
    if (!flag) {
      this.setState({
        legalNameError: "",
        totalUnitError: "",
        addressError: "",
        stateError: "",
        cityerror: "",
        zipError: "",
        projectError: "",
        otherCommentError: "",
        hoaError: "",
      });
      this.getData();
      console.log(
        "this.state.oldData.projectProfile",
        this.state.oldData.projectProfile
      );
    }

    if (flag) {
      setTimeout(() => {
        this.initializeAutoComplete();
      }, 300);
    }
  };

  toggleLenderEdit = (flag) => {
    this.setState({
      isEdit: flag,
    });
    if (!flag) {
      this.setState({
        legalNameError: "",
        totalUnitError: "",
        addressError: "",
        stateError: "",
        cityerror: "",
        zipError: "",
        projectError: "",
        otherCommentError: "",
        hoaError: "",
      });
      this.getLenderData();
    }
  };

  handleChange = (event) => {
    this.state.projectInfo[event.target.name] = event.target.value;
    this.setState({
      projectInfo: this.state.projectInfo,
    });
    console.log("this.state.projectInfo", this.state.projectInfo);
  };

  handleLenderChange = (event) => {
    this.state.lenderData[event.target.name] = event.target.value;
    this.setState({
      lenderData: this.state.lenderData,
    });
    console.log("this.state.lenderData", this.state.lenderData);
  };

  handleSaveRestrictions = async () => {
    if (isProjectStandardRole()) {
      console.log("handleSaveRestrictions  == ", this.state.formData);
      const passData = JSON.parse(sessionStorage.getItem("passData"));

      let userId = passData.user;

      let preConSelect = [];
      if (this.state.formData.preCannedDR) {
        preConSelect = this.state.formData.preCannedDR.filter(
          (c) => c.isExistingRestrictions
        );
      }

      let customConSelect = [];
      if (this.state.formData.customDR) {
        customConSelect = this.state.formData.customDR.filter(
          (c) => c.isExistingRestrictions
        );
      }

      let addedConSelect = [];
      if (this.state.formData.addedRestrictions) {
        addedConSelect = this.state.formData.addedRestrictions.map(
          ({ restrictionsName, restrictionsDescription }) => ({
            restrictionsName,
            restrictionsDescription,
            isAddedRestrictions: true,
          })
        );
      }

      this.setState({
        apiErrRestrictions: "",
        internalErrRestrictions: false,
      });

      this.setState({ restrictionErr: "" });
      const comboSelect = preConSelect.concat(addedConSelect, customConSelect);
      const entirePhaseData = {
        projectId: this.state.project.projectId,
        createdById: userId,
        manRestrictions: comboSelect,
      };
      axios
        .post(
          global.REACT_APP_API_ENDPOINT +
          `/delvyRestrictions/saveProjectManualDeliveryRestrictions/`,
          entirePhaseData
        )
        .then((response) => {
          if (response.status == 200) {
            this.setState({
              isEdit: false,
              apiErrRestrictions: "",
              internalErrRestrictions: false,
            });
            this.getData();
          } else if (response.data && response.data.message) {
            if (response.data.message == "Internal server error") {
              this.setState({
                internalErrRestrictions: true,
                apiErrRestrictions: "",
                isEdit: false,
              });
              // window.location.href = "/error-page";
            } else {
              this.setState({
                apiErrRestrictions: response.data.message,
              });
            }
          }
          window.location.reload(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  handleSaveEdit = async () => {
    console.log("handleSaveEdit  == ", this.state.projectInfo);

    if (this.state.projectInfo.projectName == "") {
      this.setState({
        legalNameError: "This field is required",
      });
    } else {
      this.setState({
        legalNameError: "",
      });
    }

    const regexUnit = new RegExp(/^[0-9]*$/);
    if (this.state.projectInfo.unitCount.length == 0) {
      this.setState({
        totalUnitError: "This field is required",
      });
    } else if (this.state.projectInfo.unitCount <= 0) {
      this.setState({
        totalUnitError: "Number of units cannot be 0 or negative",
      });
    } else if (regexUnit.test(this.state.projectInfo.unitCount) === false) {
      this.setState({
        totalUnitError: "Invalid Unit Number",
      });
    } else {
      this.setState({
        totalUnitError: "",
      });
    }

    if (
      this.state.projectInfo.projectStyle == "Other" &&
      (this.state.projectInfo.styleComments == "" ||
        this.state.projectInfo.styleComments == null)
    ) {
      this.setState({
        otherCommentError: "This field is required",
      });
    } else {
      this.setState({
        otherCommentError: "",
      });
    }
    if (this.state.projectInfo.street == "") {
      this.setState({
        addressError: "This field is required",
      });
    } else {
      this.setState({
        addressError: "",
      });
    }
    if (this.state.projectInfo.state == "") {
      this.setState({
        stateError: "This field is required",
      });
    } else {
      this.setState({
        stateError: "",
      });
    }

    const regex = new RegExp(/^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/);
    if (this.state.projectInfo.city == "") {
      this.setState({
        cityerror: "This field is required",
      });
    } else if (regex.test(this.state.projectInfo.city) === false) {
      this.setState({
        cityerror: "Invalid City",
      });
    } else {
      this.setState({
        cityerror: "",
      });
    }

    const regexZip = new RegExp(/^[0-9]{5}$/);
    if (this.state.projectInfo.zip5 == "") {
      this.setState({
        zipError: "This field is required",
      });
    } else if (regexZip.test(this.state.projectInfo.zip5) === false) {
      this.setState({
        zipError: "Invalid Zip Code",
      });
    } else {
      this.setState({
        zipError: "",
      });
    }

    const regexHoa = new RegExp(/^(\d{2})[-]?\d{7}$/);
    if (this.state.projectInfo.hoaTaxId) {
      if (this.state.projectInfo.hoaTaxId.length == 0) {
        this.setState({
          hoaError: "",
        });
      } else if (regexHoa.test(this.state.projectInfo.hoaTaxId) === false) {
        this.setState({
          hoaError: "Invalid Tax ID (format XX-XXXXXXX)",
        });
      } else {
        this.setState({
          hoaError: "",
        });
      }
    }

    if (
      this.state.projectInfo.projectName !== "" &&
      this.state.projectError == "" &&
      this.state.projectInfo.unitCount > 0 &&
      this.state.projectInfo.street !== "" &&
      this.state.projectInfo.state !== "" &&
      this.state.hoaError == "" &&
      this.state.projectInfo.city !== "" &&
      this.state.cityerror == "" &&
      this.state.projectInfo.zip5 !== "" &&
      this.state.zipError == ""
    ) {
      if (
        this.state.projectInfo.projectStyle == "Other" &&
        (this.state.projectInfo.styleComments == "" ||
          this.state.projectInfo.styleComments == null)
      ) {
        this.setState({
          otherCommentError: "This field is required",
        });
      } else {
        this.setState({
          showConfirmModal: true,
        });
      }
    }
  };

  handleSaveLenderEdit = async () => {
    console.log("handleSaveEdit  == ", this.state.lenderData);

    if (this.state.lenderData.projectName == "") {
      this.setState({
        legalNameError: "This field is required",
      });
    } else {
      this.setState({
        legalNameError: "",
      });
    }

    const regexUnit = new RegExp(/^[0-9]*$/);
    if (this.state.lenderData.unitCount.length == 0) {
      this.setState({
        totalUnitError: "This field is required",
      });
    } else if (this.state.lenderData.unitCount <= 0) {
      this.setState({
        totalUnitError: "Number of units cannot be 0 or negative",
      });
    } else if (regexUnit.test(this.state.lenderData.unitCount) === false) {
      this.setState({
        totalUnitError: "Invalid Unit Number",
      });
    } else {
      this.setState({
        totalUnitError: "",
      });
    }

    if (
      this.state.lenderData.projectStyle == "Other" &&
      this.state.lenderData.styleComments == ""
    ) {
      this.setState({
        otherCommentError: "This field is required",
      });
    } else {
      this.setState({
        otherCommentError: "",
      });
    }
    if (this.state.lenderData.street == "") {
      this.setState({
        addressError: "This field is required",
      });
    } else {
      this.setState({
        addressError: "",
      });
    }
    if (this.state.lenderData.state == "") {
      this.setState({
        stateError: "This field is required",
      });
    } else {
      this.setState({
        stateError: "",
      });
    }

    const regex = new RegExp(/^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/);
    if (this.state.lenderData.city == "") {
      this.setState({
        cityerror: "This field is required",
      });
    } else if (regex.test(this.state.lenderData.city) === false) {
      this.setState({
        cityerror: "Invalid City",
      });
    } else {
      this.setState({
        cityerror: "",
      });
    }

    const regexZip = new RegExp(/^[0-9]{5}$/);
    if (this.state.lenderData.zip5 == "") {
      this.setState({
        zipError: "This field is required",
      });
    } else if (regexZip.test(this.state.lenderData.zip5) === false) {
      this.setState({
        zipError: "Invalid Zip Code",
      });
    } else {
      this.setState({
        zipError: "",
      });
    }

    const regexHoa = new RegExp(/^(\d{2})[-]?\d{7}$/);
    if (this.state.lenderData.hoaTaxId.length == 0) {
      this.setState({
        hoaError: "",
      });
    } else if (regexHoa.test(this.state.lenderData.hoaTaxId) === false) {
      this.setState({
        hoaError: "Invalid Tax ID (format XX-XXXXXXX)",
      });
    } else {
      this.setState({
        hoaError: "",
      });
    }

    if (
      this.state.lenderData.projectName !== "" &&
      this.state.projectError == "" &&
      this.state.lenderData.unitCount > 0 &&
      this.state.lenderData.street !== "" &&
      this.state.lenderData.state !== "" &&
      this.state.hoaError == "" &&
      this.state.lenderData.city !== "" &&
      this.state.cityerror == "" &&
      this.state.lenderData.zip5 !== "" &&
      this.state.zipError == ""
    ) {
      if (
        this.state.lenderData.projectStyle == "Other" &&
        this.state.lenderData.styleComments == ""
      ) {
        this.setState({
          otherCommentError: "This field is required",
        });
      } else {
        this.saveLenderDataAPI(this.state.lenderData);
      }
    }
  };

  getData() {
    // try {
    let id = this.props.match.params.project_id;
    axios
      .get(
        global.REACT_APP_API_ENDPOINT +
        `/condoMgr/getProjectDetailsByProjectId/` +
        id
      )
      .then((response) => {
        if (response.data === `Cannot find project:${id}`) {
          console.warn(response.data);
          return;
        }
        console.log("success update", response);

        this.setState({
          project: response.data,
          projectInfo: response.data.projectProfile,
        });
        console.log(this.state.projectInfo, "response project--->");
        this.addProject();
        localStorage.setItem(
          "project" + [this.state.project.projectId],
          JSON.stringify(this.state.project.projectProfile)
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getLenderData() {
    const passData = JSON.parse(sessionStorage.getItem("passData"));

    // try {
    const lenderId =
      sessionStorage.getItem("lenderId") ||
      passData.sellerServicerCoNo ||
      "12346";
    let id = this.props.match.params.project_id;
    let userId = passData.user;
    axios
      .get(
        global.REACT_APP_API_ENDPOINT +
        `/lender/lenderViewProject/` +
        id +
        "/" +
        lenderId
      )
      .then((response) => {
        console.log("success lender update", response);

        this.setState({
          lenderData: response.data,
        });
        console.log(this.state.lenderData, "response lender data--->");
        this.addProject();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  saveDataAPI(projectInfo) {
    console.log("save API call");
    const passData = JSON.parse(sessionStorage.getItem("passData"));
    this.setState({
      apiErrProject: "",
      internalErrProject: false,
    });
    projectInfo.newProjectType = this.state.editProjectType;
    projectInfo.createdBy = passData.user;

    axios
      .post(
        global.REACT_APP_API_ENDPOINT +
        `/condoMgr/updateProjectProfileByProjectId/`,
        projectInfo
      )
      .then((response) => {
        if (response.status == 200) {
          this.setState({
            isEdit: false,
            apiErrProject: "",
            internalErrProject: false,
          });
          this.getData();
          this.submitHandler(null);
        } else if (response.data && response.data.message) {
          if (response.data.message == "Internal server error") {
            this.setState({
              internalErrProject: true,
              apiErrProject: "",
              isEdit: false,
            });
          } else {
            this.setState({
              apiErrProject: response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  saveLenderDataAPI(lenderData) {
    console.log("save API call");
    const passData = JSON.parse(sessionStorage.getItem("passData"));

    this.setState({
      apiErrLenderProj: "",
      internalErrLenderProj: false,
    });

    lenderData.lenderSSNId = passData.user;

    axios
      .post(
        global.REACT_APP_API_ENDPOINT + `/lender/lenderEditProject`,
        lenderData
      )
      .then((response) => {
        console.log("success update", response);

        if (response.status == 200) {
          this.setState({
            isEdit: false,
            apiErrLenderProj: "",
            internalErrLenderProj: false,
          });
          this.getLenderData();
        } else if (response.data && response.data.message) {
          if (response.data.message == "Internal server error") {
            this.setState({
              internalErrLenderProj: true,
              apiErrLenderProj: "",
              isEdit: false,
            });
            // window.location.href = "/error-page";
          } else {
            this.setState({
              apiErrLenderProj: response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getPersList(persList) {
    let list = [];
    if (persList.length > 0) {
      list = persList.map((pers) => {
        return {
          ...pers,
          initialSelectionMap: pers.initialSelectionMap
            ? Object.values(pers.initialSelectionMap)
            : [],
          decisionedMap: pers.decisionedMap
            ? Object.values(pers.decisionedMap)
            : [],
        };
      });
    }
    return list;
  }

  addProject() {
    this.setState({
      projectId: this.state.project.projectId,
      phaseList: this.state.project.phaseDataList,
      persList: this.getPersList(this.state.project.persDataList),
      projectPersStatus: this.state.project.persStatus,
      newProjectType: this.state.project.projectProfile.newProjectType,
      altProjectName: this.state.project.projectProfile.altProjectName,
      projectName: this.state.project.projectProfile.projectName,
      projectTypeStatus: this.state.project.projectProfile.projectTypeStatus,
      city: this.state.project.projectProfile.city,
      hoaName: this.state.project.projectProfile.hoaName,
      hoaTaxId: this.state.project.projectProfile.hoaTaxId,
      masterAssociationName:
        this.state.project.projectProfile.masterAssociationName,
      projectStyle: this.state.project.projectProfile.projectStyle,
      projectType: this.state.project.projectProfile.projectType,
      state: this.state.project.projectProfile.state,
      street: this.state.project.projectProfile.street,
      styleComments: this.state.project.projectProfile.styleComments,
      unitCount: this.state.project.projectProfile.unitCount,
      zip5: this.state.project.projectProfile.zip5,
      zipPlus4: this.state.project.projectProfile.zipPlus4,
      totalPhasesCount: this.state.project.projectProfile.totalPhasesCount,
      /*approvedPhaseCount: this.state.project.phaseDataList.filter(t => t.fmReviewStatus === "Approved by Fannie Mae").length,*/
    });
  }

  changeHandler = (e) => {
    if (e.target.scrollHeight > e.target.clientHeight) {
      e.target.style.height = e.target.scrollHeight + "px";
    }
    this.setState({
      [e.target.name]: e.target.value,
      commentCharCount: e.target.value.length,
    });
  };

  submitHandler = (e) => {
    if (e) {
      e.preventDefault();
    }
    this.setState({
      disablePostButton: true,
    });
    const passData = JSON.parse(sessionStorage.getItem("passData"));
    this.setState({
      apiErrComment: "",
      internalErrComment: false,
    });

    axios
      .post(
        global.REACT_APP_API_ENDPOINT + `/condoMgr/postComments/`,
        this.state
      )
      .then((response) => {
        console.log("postComments", response);

        if (response.status == 200) {
          this.setState({
            apiErrComment: "",
            internalErrComment: false,
          });
          window.location.reload(false);
        } else if (response.data && response.data.message) {
          if (response.data.message == "Internal server error") {
            this.setState({
              internalErrComment: true,
              apiErrComment: "",
            });
            // window.location.href = "/error-page";
          } else {
            this.setState({
              apiErrComment: response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  renderComments() {
    let list = <h1>Loading...</h1>;
    let comments = this.state.getComments;
    if (comments?.length > 0) {
      list = <Comments list={comments} projectId={this.props.match.params.project_id} showExternalToggle={true} />;
    } else {
      list = <></>;
    }
    return list;
  }

  renderDecision2() {
    let list = <p>Loading...</p>;
    if (this.state.projectRestrictions) {
      list = (
        <ProjectRestrictions list={this.state.projectRestrictions.projectDR} />
      );
    }
    return list;
  }

  renderLenders() {
    let list = <h1>Loading...</h1>;
    if (this.state.getLenders) {
      list = <LenderList list={this.state.getLenders} />;
    }
    return list;
  }

  renderPdf() {
    let list = <h1></h1>;
    if (this.state.getPdf) {
      list = <Pdf list={this.state.getPdf} />;
    }
    return list;
  }

  renderPhase() {
    let list = <h1>Loading...</h1>;
    if (this.state.phaseList) {
      list = <PhaseList list={this.state.phaseList} />;
    }
    return list;
  }

  renderFmActivity() {
    const { fmActivity } = this.state;
    let list = <h1>Loading...</h1>;
    if (fmActivity) {
      //consider moving this to a component when doing the fm activity feed for phase and childphase
      list = (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            maxHeight: "600px",
            overflowY: "auto",
          }}
        >
          {fmActivity.map((ele, index) => (
            <FmActivityFeed ele={ele} index={index} reasonCodes={this.state.reasonCodes}/>
          ))}
        </div>
      );
    }
    return list;
  }

  renderLenderCertification() {
    let list = <h1>Loading...</h1>;
    if (this.state.lenderCertificationList) {
      list = (
        <LenderCertificationList list={this.state.lenderCertificationList} />
      );
    }
    return list;
  }

  renderPers() {
    let list = <h1>Loading...</h1>;
    if (this.state.persList && this.state.persList?.length > 0) {
      list = (
        <PersList
          list={this.state.persList}
          isEstablished={this.state.newProjectType == "Established Condo"}
        />
      );
    } else {
      list = <></>;
    }
    return list;
  }

  renderTooltipTitle() {
    return (
      <div style={{ lineHeight: 1.5, fontSize: 14 }}>
        <span style={{ color: "#1c6fa3", width: 16, height: 16 }}>
          {" "}
          <InfoIcon />
        </span>
        <br />
        <div
          style={{
            color: "rgba(0,0,0,0.82)",
            fontFamily: "Source Sans Pro",
            fontSize: "16px",
            letterSpacing: "0",
            lineHeight: "24px",
            marginTop: "5px",
            marginBottom: "5px",
          }}
        >
          There is a PERS associated to this Project. Please use the PERS
          decision workflow to edit Delivery Restrictions.
        </div>
      </div>
    );
  }

  renderPhaseTooltip() {
    return (
      <div style={{ lineHeight: 1.5, fontSize: 14 }}>
        <span
          style={{
            fontSize: 18,
            fontFamily: "Source Sans Pro",
            fontWeight: 600,
            fontFamily: "Source Sans Pro",
            color: "rgba(0,0,0,0.82)",
            lineHeight: "27px",
          }}
        >
          {" "}
          Don't see the phase(s) you are looking for?{" "}
        </span>
        <br />
        <span
          style={{
            fontSize: 16,
            color: "rgba(0,0,0,0.82)",
            lineHeight: "24px",
            letterSpacing: "0px",
          }}
        >
          {"Please review"}
        </span>
        <p
          style={{
            color: "#1176B5",
            fontSize: 16,
            lineHeight: "24px",
            letterSpacing: "0px",
          }}
        >
          <a href=" https://singlefamily.fanniemae.com/media/document/pdf/cpm-add-or-update-projectphase-job-aid">
            {" "}
            Add/Update Project Instructions
          </a>
          <p
            style={{
              fontSize: 16,
              color: "rgba(0,0,0,0.82)",
              letterSpacing: "0px",
            }}
          >
            {"for steps to add phase(s)."}
          </p>
        </p>
      </div>
    );
  }

  renderUpdateProjectDetailsTooltip() {
    return (
      <div style={{ lineHeight: 1.5, fontSize: 14 }}>
        <span
          style={{
            fontSize: 18,
            fontFamily: "Source Sans Pro",
            fontWeight: 600,
            fontFamily: "Source Sans Pro",
            color: "rgba(0,0,0,0.82)",
            lineHeight: "27px",
          }}
        >
          {" "}
          Do project details need to be updated?{" "}
        </span>
        <br />
        <span
          style={{
            fontSize: 16,
            color: "rgba(0,0,0,0.82)",
            lineHeight: "24px",
            letterSpacing: "0px",
          }}
        >
          {"Please review "}
        </span>
        <p
          style={{
            color: "#1176B5",
            fontSize: 16,
            lineHeight: "24px",
            letterSpacing: "0px",
          }}
        >
          <a href=" https://singlefamily.fanniemae.com/media/document/pdf/cpm-add-or-update-projectphase-job-aid">
            {" "}
            Add/Update Project Instructions
          </a>
          <p
            style={{
              fontSize: 16,
              color: "rgba(0,0,0,0.82)",
              letterSpacing: "0px",
            }}
          >
            {"for steps to update project details."}
          </p>
        </p>
      </div>
    );
  }
  collapseAll() {
    if (this.state.collapseKey === "1") {
      this.setState((state) => ({
        collapseKey: "0",
      }));
    } else if (this.state.collapseKey === "0") {
      this.setState((state) => ({
        collapseKey: "1",
      }));
    }
  }

  // shouldComponentUpdate(prevProps, prevState){

  //   console.log(prevProps,prevState)
  //   window.addEventListener('storage', () => {
  //     // When local storage changes, dump the list to
  //     // the console.
  //     console.log('try to update')
  //   });
  // }

  downloadFile = (event) => {
    const a = document.createElement("a");
    a.style.display = "none";
    a.href =
      global.REACT_APP_API_ENDPOINT +
      `/condoMgr/projectProfile/export/excel/${this.state.projectId}`;
    $(a).attr("download", true);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  hideComponent(name) {
    switch (name) {
      case "showHideInfo":
        this.setState({ showHideInfo: !this.state.showHideInfo });
        this.setState({
          showHideRestrictions: !this.state.showHideRestrictions,
        });
        break;
      case "showHideRestrictions":
        this.setState({
          showHideRestrictions: !this.state.showHideRestrictions,
        });
        break;
      default:
        break;
    }
  }



  getProjectDetailStatus = (projectData) => {
    if (projectData && projectData.projectProfile) {   
      // projectData.projectProfile.fnmaReviewStatus   
      switch (projectData.projectProfile.fnmaReviewStatus) {
        case "Approved By Fannie Mae":
          return {
            icon: <img alt="Success Icon" src={SuccessIcon} />,
            name: "Approved By Fannie Mae",
          };
        case "Pending PERS Review":
          return {
            icon: <img alt="Timer Icon" src={TimerIcon} />,
            name: "Pending PERS Review",
          };
        case "Conditional Approval":
          return {
            icon: <img alt="Warning Icon" src={WarningIcon} />,
            name: "Conditional Approval",
          };
        case "Unavailable":
          return {
            icon: <img alt="Removal Icon" src={FeedbackRemoveIcon} />,
            name: "Unavailable",
          };
        case "Fannie Mae Approval Expired":
          return {
            icon: <img alt="Alternate Success Icon" src={Success1Icon} />,
            name: "Fannie Mae Approval Expired",
          };
        case "Fannie Mae Conditional Approval Expired":
          return {
            icon: <img alt="Alternate Warning Icon" src={Warning1Icon} />,
            name: "Conditional Approval Expired",
          };
        default:
          return { icon: null, name: "No Fannie Mae Review" };
      }
    }
  };

  getProjectDetailStatusByLender = (lenderData) => {
    if (lenderData) {
      // projectData.projectProfile.fnmaReviewStatus
      switch (lenderData.fnmaReviewStatus) {
        case "Approved By Fannie Mae":
          return {
            icon: <img alt="Success Icon" src={SuccessIcon} />,
            name: "Approved By Fannie Mae",
          };
        case "Pending PERS Review":
          return {
            icon: <img alt="Timer Icon" src={TimerIcon} />,
            name: "Pending PERS Review",
          };
        case "Conditional Approval":
          return {
            icon: <img alt="Warning Icon" src={WarningIcon} />,
            name: "Conditional Approval",
          };
        case "Unavailable":
          return {
            icon: <img alt="Removal Icon" src={FeedbackRemoveIcon} />,
            name: "Unavailable",
          };
        case "Fannie Mae Approval Expired":
          return {
            icon: <img alt="Alternate Success Icon" src={Success1Icon} />,
            name: "Fannie Mae Approval Expired",
          };
        case "Fannie Mae Conditional Approval Expired":
          return {
            icon: <img alt="Alternate Warning Icon" src={Warning1Icon} />,
            name: "Conditional Approval Expired",
          };
        default:
          return { icon: null, name: "No Fannie Mae Review" };
      }
    }
  };

  showMannualOverrideButton = (projectData) => {
    if (projectData && projectData.projectProfile) {
      if(projectData.projectProfile.showManualOverrideButton 
        && isAdminRole() && this.state.persList.length > 0) {
        return false;
      }
      return projectData.projectProfile.showManualOverrideButton;
    }
  };

  getExpiry = (projectData, isLenderData = false) => {
    if (isLenderData) {
      return projectData.fnmaReviewStatusExpyDate;
    }
    if (projectData && projectData.projectProfile) {
      return projectData.projectProfile.fnmaReviewStatusDtExpiry;
    }
  };



  renderExpiry(data, isLender = false) {
    return (
      <>
        {this.getExpiry(data, isLender) ? (
          <div style={{ display: "flex" }}>
            <div>Expiration: </div>
            <div>
              <span style={{ fontWeight: "bold", paddingLeft: "5px" }}>
              {
                 this.getFmStatus(isLender) === 'Approved By Fannie Mae' && getDays(this.getExpDateByUser( data, isLender)) <= 120 &&
                <CustomTooltip title={"Expires in "+  getDays(this.getExpDateByUser( data, isLender)) + " days" }  placement="bottom"><AccessTimeIcon className="clock" /></CustomTooltip>
              } {this.getExpiry(data, isLender)}
              </span>
            </div>
          </div>
        ) : null}
      </>
    );
  }

  getExpDateByUser = (data, isLender) => {
    if ( isLender) {
      return this.getExpiry(data, isLender);
    } else {
      return this.getExpiry(data);
    }
  }

  getFmStatus = (isLender) => {
    if ( isLender) {
      return this.state.lenderData.fnmaReviewStatus;
    } else {
      return this.state.project.projectProfile.fnmaReviewStatus;
    }
  }

  getDropDownStatusVal = (projectData) => {
    if (projectData && projectData.projectProfile) {
      console.log("inside getdropdownval $$$$   ", projectData.projectProfile);
      return projectData.projectProfile.manualOverrideCanChangeTo;
    }
  };

  getProjectDetails = (projectData) => {
    if (projectData && projectData.projectProfile) {
      const { projectId, fnmaReviewStatus, createdBy } =
        projectData.projectProfile;
      return {
        type: "project",
        projectId: projectId,
        fnmaReviewStatus: fnmaReviewStatus,
        createdBy: createdBy,
      };
    }
  };

  getSavedProjectResponse = (response) => {
    console.log("savedres", response);
    this.setState({
      project: {
        ...this.state.project,
        projectProfile: {
          ...this.state.project.projectProfile,
          showManualOverrideButton: response.showManualOverrideButton,
          fnmaReviewStatus: response.fnmaReviewStatus,
          fnmaReviewStatusDtExpiry: response.approvalExpiryDate,
        },
      },
      showContainerCModal: false,
    });
  };

  getSaveConfirmation = (response) => {
    this.setState({
      comments: response,
      commentCharCount: response.length,
      showConfirmModal: false,
    });
    console.log(this.state);
    this.saveDataAPI(this.state.projectInfo);
  };

  markCommentsRead() {
    console.log('markCommentsRead')
    const passData = JSON.parse(sessionStorage.getItem("passData"));
    const userId = passData.user;
    const projectId = this.state.projectId;
    try {
      axios.post(
        global.REACT_APP_API_ENDPOINT +
        `/condoMgr/markViewed/comments/${projectId}/${userId}`
      );
    } catch (err) {
      console.log(err);
    }
    this.setState({
      getComments: this.state.getComments.map(item => ({
        isRead: true,
        ...item
      })),
      hasNewComments: false
    });
  }

  markPdfsRead() {
    console.log('markPdfsRead')
    // const unread = this.state.getPdf.filter(item => !item.isRead);
    const passData = JSON.parse(sessionStorage.getItem("passData"));
    const userId = passData.user;
    const projectId = this.state.projectId;
    try {
      axios.post(
        global.REACT_APP_API_ENDPOINT +
        `/condoMgr/markViewed/attachments/${projectId}/${userId}`
      );
    } catch (err) {
      console.log(err);
    }
    this.setState({
      getPdf: this.state.getPdf.map(item => ({
        isRead: true,
        ...item
      })),
      hasNewPdfs: false
    });
  }

  render() {
    // console.log("project page render", this.state.project, this.state.unitType);
    $(window).on("hashchange", (e) => {
      if (window.location.hash) {
        let target = $(window.location.hash);
        window.scrollTo({ top: target.position().top - 50 });
      }
    });

    const { comments, showHideInfo, showHideRestrictions } = this.state;
    const phasedProjectInd = this.state.phasedProjectInd;
    let projectIndicator;
    if (phasedProjectInd === true) {
      projectIndicator = "Yes";
    } else if (phasedProjectInd === false) {
      projectIndicator = "No";
    } else {
      projectIndicator = "Not Available";
    }

    const masterAssocInd = this.state.masterAssocInd;
    let masterIndicator;
    if (masterAssocInd === true) {
      masterIndicator = "Yes";
    } else if (masterAssocInd === false) {
      masterIndicator = "No";
    } else {
      masterIndicator = "Not Available";
    }

    const nonGutRehabInd = this.state.nonGutRehabInd;
    let rehabIndicator;
    if (nonGutRehabInd === true) {
      rehabIndicator = "Yes";
    } else if (nonGutRehabInd === false) {
      rehabIndicator = "No";
    } else {
      rehabIndicator = "Not Available";
    }

    const subjectToLegalActionId = this.state.subjectToLegalActionId;
    let subjectIndicator;
    if (subjectToLegalActionId === true) {
      subjectIndicator = "Yes";
    } else if (subjectToLegalActionId === false) {
      subjectIndicator = "No";
    } else {
      subjectIndicator = "Not Available";
    }

    const setRestrictionsData = (_restrictions, name) => {
      const _formData = { ...this.state.formData, [name]: [..._restrictions] };
      this.setState({ formData: _formData });
    };
    const handleAddClose = () => this.setState({ showAdd: false });
    const handleAddClick = (newRestriction) => {
      const _formData = {
        ...this.state.formData,
        addedRestrictions: [
          ...(this.state.formData.addedRestrictions || []),
          { ...newRestriction },
        ],
      };
      this.setState({ formData: _formData });
      this.setState({ showAdd: false });
    };
    const handleAddShow = () => this.setState({ showAdd: true });
    const changeTab = (params) => {
      this.setState({ activeTab: params });
    };
    const { addedRestrictions = [], restrictions = [] } = this.state.formData;

    const userRole = sessionStorage.getItem("role");

    const handleProjectTypeChange = (event) => {
      const type = event.target.value;
      this.setState({
        editProjectType: type,
      });
    };

    // console.log("isProjectRole", isProjectRole());
    // console.log("render lenders", this.renderLenders().props.list);
    // console.log("project", this.state.project);

    const getProjectStatus = () => {
      const status =
        this.state.lenderData.lenderCertificationStatus === "Incomplete"
          ? "Incomplete"
          : this.state.newProjectType === "New Condo"
            ? "new"
            : this.state.newProjectType === "Established Condo"
              ? "est"
              : "";
      return status;
    };

    const renderCreateCertificationButton = () => {
      const status =
        this.state.lenderData.lenderCertificationStatus === "Incomplete"
          ? "Incomplete"
          : this.state.newProjectType === "New Condo"
            ? "new"
            : this.state.newProjectType === "Established Condo"
              ? "est"
              : "";
      return (
        <>
          {!this.state.phaseList.length ||
            this.state.newProjectType === "Established Condo" ? (
            <a href={`/certification/${this.state.projectId}/proj/${status}`}>
              <button className="item-d-container-button">
                Certify Project
              </button>
            </a>
          ) : (
            <CertifyModal
              projectvalue={this.state.projectId}
              status={this.state.lenderData.lenderCertificationStatus}
            />
          )}
        </>
      );
    };

    return (
      <div id="main">
        <div
          className="title-container sticky-top"
          style={{ marginLeft: "0px", paddingLeft: "78px" }}
        >
          <div>
            <a className="update-cookie-dash" href={"/"}>
              <HomeIcon
                style={{
                  fontSize: "1rem",
                  marginRight: "10px",
                  marginBottom: "4px",
                  color: "#0A649D",
                }}
              />
              Home
            </a>{" "}
            <RemoveIcon style={{ marginLeft: "8px", marginRight: "8px" }} />
            <a className="update-cookie-dash">
              <img
                alt="Apartment Icon"
                style={{
                  marginRight: "10px",
                  marginBottom: "4px",
                  width: "17px",
                  height: "17px",
                }}
                src={ApartmentIcon}
              />
              {this.state.projectName}
            </a>
          </div>
          <p className="update-project-name">{this.state.projectName}</p>
          <p className="project-id-container">
            <img
              alt="Apartment Icon"
              style={{ marginRight: "10px", marginBottom: "4px" }}
              src={ApartmentIcon}
            />
            Project ID: {this.state.projectId}
          </p>
        </div>
        <div
          id="project-profile-top-phase"
          className="project-profile-top-phase"
        >
          {typeof this.state.projectRestrictions !== "undefined" ? (
            <>
              {typeof this.state.projectRestrictions.projectDR !==
                "undefined" &&
                this.state.projectRestrictions.projectDR.length > 0 ? (
                <div id="item-e-phase" className="item-e-phase">
                  <div className="item-e-title">
                    <img
                      alt="Critical Warning Icon"
                      className="item-e-image"
                      src={CriticalIcon}
                    />
                    Delivery Restrictions
                  </div>
                </div>
              ) : (
                <div
                  id="item-e-phase"
                  className="item-e-phase"
                  style={{ all: "unset" }}
                ></div>
              )}
            </>
          ) : (
            ""
          )}
          <div id="item-a-phase" className="item-a-phase">
            {this.state.newProjectType === "New Condo" ? (
              <div className="image-pill">
                <div /*className="image-pill-date"*/>
                  {/* {this.state.project.projectProfile.projectName} */}
                  {/* Date Created:{" "}
                  {this.state.project.projectProfile.dateCreated.slice(0, 10)} */}
                </div>
                <div className="image-pill-type">
                  {this.state.newProjectType} PROJECT
                </div>
              </div>
            ) : this.state.newProjectType === "Established Condo" ? (
              <div className="image-pill-established">
                {/* <div className="image-pill-date">
                  {this.state.project.projectProfile.projectName}
                </div> */}
                <div className="image-pill-type">
                  {this.state.newProjectType} PROJECT
                </div>
              </div>
            ) : (
              ""
            )}
            {this.state.newProjectType === "New Condo" ? (
              <img
                alt="New Condo Image"
                className="center-image"
                src={NewCondoIcon}
              />
            ) : this.state.newProjectType === "Established Condo" ? (
              <img
                alt="Established Condo Image"
                className="center-image"
                src={EstCondoIcon}
              />
            ) : (
              ""
            )}
            {this.state.totalPhasesCount ? (
              <>
                <div className="phase-pill">
                  <img
                    alt="Apartment Icon"
                    style={{
                      height: "16px",
                      width: "16px",
                      margin: "0px 5px 2px 5px",
                    }}
                    src={LayersBlackIcon}
                  />
                  Contains {this.state.totalPhasesCount} Phases
                </div>
                <div>
                  {this.state.project.phaseDataList.filter(
                    (t) => t.fmReviewStatus === "Approved By Fannie Mae"
                  ).length ? (
                    <div className="approved-phase-pill">
                      <CheckCircleIcon
                        style={{
                          fill: "green",
                          height: "20px",
                          marginBottom: "1px",
                        }}
                      />
                      {
                        this.state.project.phaseDataList.filter(
                          (t) => t.fmReviewStatus === "Approved By Fannie Mae"
                        ).length
                      }{" "}
                      Approved Phases
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
          <div id="item-b-phase" className="item-b-phase">
            <div className="item-b-container">
              <div className="item-b-container-top">
                {/* <img src={Group17Icon} /> */}
                <img
                  alt="Map Icon"
                  src={Group17Icon}
                  height="47px"
                  style={{ height: "47px" }}
                />
              </div>
              <div className="item-b-container-bottom">
                <div className="top">Project Address:</div>
                <div className="bottom">
                  <div>{this.state.street}</div>
                  <div>
                    {this.state.city}, {this.state.state} {this.state.zip5}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="item-c-phase" className="item-c-phase">
            <div className="item-c-container">
              <div className="item-c-container-top">
                {this.showMannualOverrideButton(this.state.project) &&
                  isInternal() && (
                    <img
                      alt="Edit Icon"
                      src={EditIcon}
                      className="item-c-container-top-edit"
                      onClick={() => {
                        this.setState({
                          showContainerCModal: true,
                        });
                      }}
                    />
                  )}

                <img
                  alt="Fannie Mae Icon"
                  src={FannieMaeIcon}
                  style={{ marginRight: "13px" }}
                />
                {isLenderRole() || isTPORole()
                  ? this.getProjectDetailStatusByLender(
                    this.state.lenderData
                  ) &&
                  this.getProjectDetailStatusByLender(this.state.lenderData)
                    .icon
                  : this.getProjectDetailStatus(this.state.project) &&
                  this.getProjectDetailStatus(this.state.project).icon}
              </div>
              <div className="item-c-container-bottom">
                <div className="top">Fannie Mae Review Status </div>
                <div className="bottom">
                  {isLenderRole() || isTPORole() ? (
                    <div>
                      {this.getProjectDetailStatusByLender(
                        this.state.lenderData
                      ) &&
                        this.getProjectDetailStatusByLender(
                          this.state.lenderData
                        ).name}
                    </div>
                  ) : (
                    <div>
                      {this.getProjectDetailStatus(this.state.project) &&
                        this.getProjectDetailStatus(this.state.project).name}
                    </div>
                  )}
                </div>
                {isLenderRole() || isTPORole()
                  ? this.renderExpiry(this.state.lenderData, true)
                  : this.renderExpiry(this.state.project)}
              </div>
            </div>
          </div>
          {isLenderRole() || isTPORole() ? (
            <div id="item-d-phase" className="item-d-phase">
              <div className="item-d-container">
                <div
                  className="item-d-container-top"
                  style={{ padding: "18px 24px 18px 24px" }}
                >
                  <img alt="Certification Icon" src={Group11Icon} />
                  {this.state.lenderData.showCertifyButton === true &&
                    !isReadonlyRole() ? (
                    renderCreateCertificationButton()
                  ) : this.state.lenderData.showViewCertificationButton ===
                    true ? (
                    <a
                      href={`/view-certification/${this.state.projectId
                        }/proj/${getProjectStatus()}`}
                    >
                      <button className="item-d-container-button">
                        View Certification
                      </button>
                    </a>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="item-d-container-bottom">
                  <div className="top" style={{ fontSize: "16px" }}>
                    Lender Certification Status
                  </div>
                  <div className="bottom">
                    <div> {this.state.lenderData.lenderCertificationStatus}</div>
                  </div>
                  {this.state.lenderData.lenderCertExpiryDate !== null ? (
                    <div style={{ display: "flex" }}>
                      <div>Expiration: </div>
                      <div>
                        <span
                          style={{ fontWeight: "bold", paddingLeft: "5px" }}
                        >
                          {this.state.lenderData.lenderCertExpiryDate}
                        </span>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          ) : (
            <div id="item-d-phase" className="item-d-phase">
              <div className="item-d-container">
                <div
                  className="item-d-container-top"
                  style={{ padding: "18px 24px 18px 24px" }}
                >
                  <img alt="Certification Icon" src={Group11Icon} />
                </div>
                <div className="item-d-container-bottom">
                  <div className="top" style={{ fontSize: "16px" }}>
                    Lender Certification Status
                  </div>
                  {this.state.certificationCount ? (
                    <div className="bottom">
                      <div>
                        {this.state.certificationCount} Lender Certifications{" "}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          )}
          <div id="item-a-sticky" className="item-a-sticky">
            {this.state.newProjectType === "New Condo" ? (
              <div className="pill">
                <div className="date">
                  Date Created:{" "}
                  {this.state.project.projectProfile.dateCreated.slice(0, 10)}
                </div>
                <div className="type">{this.state.newProjectType} PROJECT</div>
              </div>
            ) : this.state.newProjectType === "Established Condo" ? (
              <div className="pill-established">
                <div className="date">
                  Date Created:{" "}
                  {this.state.project.projectProfile.dateCreated.slice(0, 10)}
                </div>
                <div className="type">{this.state.newProjectType} PROJECT</div>
              </div>
            ) : (
              ""
            )}
          </div>
          {isProjectRole() ? (
            <div id="item-b-sticky" className="item-b-sticky">
              <img alt="Orange Timer Icon" src={Group34Icon} />
              <div className="status">PERS Review Status:</div>
              <div className="prompt">PERS Submitted:</div>
              <div className="value">
                {this.state.persList.length > 0 ? (
                  <div>Yes</div>
                ) : this.state.persList.length === 0 ? (
                  <div>No</div>
                ) : (
                  <div>N/A</div>
                )}
              </div>
              <div className="button-container">
                <a href="#section2">
                  {this.state.persList.length > 0 ? (
                    <button className="button">View PERS Section</button>
                  ) : this.state.persList.length === 0 ? (
                    ""
                  ) : (
                    ""
                  )}
                </a>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        <Navbar id="project-nav" className="project-nav" sticky="top">
          <Nav className="container-fluid">
            <Nav.Link
              href="#section0"
              className="profile-nav-button"
              onClick={this.handleNavClick}
            >
              Project Profile
            </Nav.Link>
            <Nav.Link
              href="#section1"
              className="profile-nav-button"
              onClick={this.handleNavClick}
            >
              Fannie Mae Activity Feed
            </Nav.Link>
            {isProjectRole() ? (
              <Nav.Link
                href="#section2"
                className="profile-nav-button"
                onClick={this.handleNavClick}
              >
                PERS
              </Nav.Link>
            ) : (
              <></>
            )}
            <Nav.Link
              href="#section3"
              className="profile-nav-button"
              onClick={this.handleNavClick}
            >
              Phases
            </Nav.Link>
            {isProjectRole() ? (
              <Nav.Link
                href="#section4"
                className={this.state.hasNewPdfs ? "profile-nav-button highlight" : "profile-nav-button"}
                onClick={this.handleNavClick}
              >
                Attachments
              </Nav.Link>
            ) : (
              <></>
            )}
            {isProjectRole() || isLenderRole() || isTPORole() || isAdminRole() ? (
              <Nav.Link
                href="#section5"
                className={this.state.hasNewComments ? "profile-nav-button highlight" : "profile-nav-button"}
                onClick={this.handleNavClick}
              >
                Comments
              </Nav.Link>
            ) : (
              <></>
            )}
            {isProjectRole() && this.state.getLenders.length !== 0 ? (
              <Nav.Link
                href="#section6"
                className="profile-nav-button"
                onClick={this.handleNavClick}
              >
                Lender Version
              </Nav.Link>
            ) : (
              <></>
            )}
            {(isLenderRole() || isProjectRole() || isTPORole()) &&
              this.state.lenderCertificationList.length !== 0 ? (
              <Nav.Link
                href="#section7"
                className="profile-nav-button"
                onClick={this.handleNavClick}
              >
                Lender Certification
              </Nav.Link>
            ) : (
              <></>
            )}
            <div className="ml-auto">
              {/*<button className="download-xlsx" onClick={this.downloadFile}>
                <IoMdDownload className="download-icon" /> Download XLSX
              </button>*/}
            </div>
          </Nav>
        </Navbar>
        <button className="collapse-all" onClick={this.collapseAll}>
          <MdFormatLineSpacing className="collapse-all-icon" />
          Collapse All
        </button>
        <Accordion defaultActiveKey="1">
          <a class="anchor" id="section0"></a>
          <Card className="accordion-header-card">
            <Card.Header className="accordion-header-space">
              <ContextAwareToggle eventKey={this.state.collapseKey}>
                <p className="accordion-header-title">Project Information</p>
              </ContextAwareToggle>

              {/* )  */}
              {/* : ( */}
              <></>
              {/* )} */}
              {isProjectStandardRole() ? (
                this.state.isEdit ? (
                  <>
                    {this.state.showDeleteModal ? (
                      <DeleteModal
                        type="project"
                        projectvalue={this.state.projectId}
                        phasevalue={null}
                      />
                    ) : (
                      ""
                    )}
                    <div>
                      <button
                        className="cancel-button"
                        onClick={() => {
                          this.toggleEdit(false);
                          this.setState({
                            apiErrProject: false,
                          });
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        //   disabled
                        className="add-phase-button"
                        onClick={this.handleSaveEdit}
                      >
                        <FaSave className="edit-profile-icon" /> Save Edits
                      </button>
                    </div>
                  </>
                ) : (
                  <button
                    disabled={this.state.showHideRestrictions === true}
                    className="add-phase-button"
                    onClick={() => {
                      this.toggleEdit(true);
                      this.setState({
                        internalErrProject: false,
                        apiErrProject: "",
                      });
                    }}
                  >
                    <FaPen className="edit-profile-icon" /> Edit Section
                  </button>
                )
              ) : null}
              {isLenderRole() || isTPORole() ? (
                <CustomWidthTooltip
                  style={{
                    backgroundColor: "white",
                    zIndex: 1,
                    maxWidth: "500px",
                  }}
                  title={this.renderUpdateProjectDetailsTooltip()}
                  placement="left"
                  arrow
                  disableFocusListener
                  disableTouchListener
                  interactive
                >
                  <span
                    id="request-phase"
                    style={{
                      cursor: "pointer",
                      color: "#0D6DA9",
                      fontSize: 14,
                      marginTop: "15px",
                      marginRight: "8px",
                      letterSpacing: 0,
                      lineHeight: "20px",
                    }}
                  >
                    <InfoIcon
                      style={{ width: 20, height: 18, marginBottom: "3px" }}
                    />{" "}
                    Need to edit project information?
                  </span>
                </CustomWidthTooltip>
              ) : (
                ""
              )}
            </Card.Header>
            <Accordion.Collapse eventKey={this.state.collapseKey}>
              <Card.Body className="accordion-grid-body-card">
                <div
                  style={{
                    textAlign: "right",
                    color: "#ff0000",
                    padding: 15,
                  }}
                >
                  {this.state.apiErrProject ? this.state.apiErrProject : null}
                </div>
                {this.state.internalErrProject ? (
                  <ErrorPage></ErrorPage>
                ) : (
                  <div className="accordion-grid-parent">
                    {this.state.projectRestrictions
                      .showAddManualRestrictions === true &&
                      this.state.projectRestrictions.projectDR?.length === 0 && this.state.project.persDataList?.length === 0 ? (
                      <>
                        {isProjectStandardRole() && (
                          <div className="bigbutton">
                            <div
                              onClick={() => this.hideComponent("showHideInfo")}
                              className="add-restrictions-button"
                            >
                              <img
                                alt="Add Icon"
                                className="button-image"
                                src={AddCircleIcon}
                              />
                              Add Delivery Restrictions
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                    {showHideInfo && (
                      <>
                        <div className="header">
                          <div className="title">Overview</div>
                        </div>

                        <div className="attributes">
                          <div className="attri-container">
                            <div className="attri-title">Project Type </div>
                            {(this.state.isEdit &&
                              this.state.projectInfo.newProjectType ===
                              "New Condo") ||
                              (this.state.isEdit &&
                                this.state.projectInfo.newProjectType ===
                                "Established Condo"
                                // && this.state.persList.length === 0
                              ) ? (
                              <div className="attri-value">
                                <RadioGroup
                                  row
                                  aria-label="position"
                                  name="type"
                                  defaultValue={
                                    this.state.projectInfo.newProjectType
                                  }
                                  onChange={(event) => {
                                    handleProjectTypeChange(event);
                                  }}
                                >
                                  <FormControlLabel
                                    key="New Condo"
                                    style={{ fontWeight: "normal" }}
                                    value="New Condo"
                                    control={<RadioInput />}
                                    label="New"
                                    labelPlacement="end"
                                  />
                                  <FormControlLabel
                                    key="Established Condo"
                                    style={{ fontWeight: "normal" }}
                                    value="Established Condo"
                                    control={<RadioInput />}
                                    label="Established"
                                    labelPlacement="end"
                                  />
                                </RadioGroup>
                              </div>
                            ) : (
                              <div className="attri-value">
                                {this.state.projectInfo.newProjectType}{" "}
                              </div>
                            )}
                          </div>
                          <div className="attri-container">
                            <div className="attri-title">
                              Project Legal Name{" "}
                              <span style={{ color: "#ff0000" }}>*</span>
                            </div>
                            {this.state.isEdit ? (
                              <div className="attri-value">
                                <input
                                  required
                                  aria-label="Project Legal Name"
                                  type="text"
                                  className="form-control"
                                  name="projectName"
                                  value={this.state.projectInfo.projectName}
                                  maxLength="200"
                                  onChange={(event) => {
                                    this.handleChange(event);
                                    if (event.target.value.length == 0) {
                                      this.setState({
                                        projectError: "This field is required",
                                      });
                                    } else {
                                      this.setState({
                                        projectError: "",
                                      });
                                    }
                                  }}
                                />

                                <div style={{ color: "#ff0000" }}>
                                  {this.state.projectError}
                                </div>
                              </div>
                            ) : (
                              <div className="attri-value">
                                {this.state.projectInfo.projectName}{" "}
                              </div>
                            )}
                          </div>

                          <div className="attri-container">
                            <div className="attri-title">Legacy CPM ID</div>
                            {this.state.isEdit ? (
                              <div className="attri-value">
                                <input
                                  required
                                  aria-label="Legacy CPM ID"
                                  type="text"
                                  className="form-control"
                                  name="cpmId"
                                  value={this.state.projectInfo.cpmId}
                                  maxLength="16"
                                  onChange={(event) => {
                                    this.handleChange(event);
                                  }}
                                />
                              </div>
                            ) : (
                              <div className="attri-value">
                                {this.state.projectInfo.cpmId}{" "}
                              </div>
                            )}
                          </div>

                          <div className="attri-container">
                            <div className="attri-title">
                              Master Association
                            </div>
                            {this.state.isEdit ? (
                              <div className="attri-value">
                                <input
                                  required
                                  aria-label="Master Association"
                                  type="text"
                                  className="form-control"
                                  name="masterAssociationName"
                                  value={
                                    this.state.projectInfo.masterAssociationName
                                  }
                                  maxLength="256"
                                  onChange={(event) => {
                                    this.handleChange(event);
                                  }}
                                />
                              </div>
                            ) : (
                              <div className="attri-value">
                                {this.state.projectInfo.masterAssociationName}{" "}
                              </div>
                            )}
                          </div>

                          <div className="attri-container">
                            <div className="attri-title">
                              Alternate Project Name
                            </div>
                            {this.state.isEdit ? (
                              <div className="attri-value">
                                <input
                                  required
                                  aria-label="Alternate Project Name"
                                  type="text"
                                  className="form-control"
                                  name="altProjectName"
                                  value={this.state.projectInfo.altProjectName}
                                  maxLength="200"
                                  onChange={(event) => {
                                    this.handleChange(event);
                                  }}
                                />
                              </div>
                            ) : (
                              <div className="attri-value">
                                {this.state.projectInfo.altProjectName}{" "}
                              </div>
                            )}
                          </div>

                          <div className="attri-container">
                            <div className="attri-title">
                              Address{" "}
                              <span style={{ color: "#ff0000" }}>*</span>
                            </div>
                            {this.state.isEdit ? (
                              <div className="attri-value">
                                <input
                                  ref={this.inputRef}
                                  placeholder=""
                                  required
                                  aria-label="Address"
                                  type="text"
                                  className="form-control"
                                  name="street"
                                  value={this.state.projectInfo.street}
                                  maxLength="256"
                                  onChange={(event) => {
                                    this.handleChange(event);
                                    if (event.target.value.length == 0) {
                                      this.setState({
                                        addressError: "This field is required",
                                      });
                                    } else {
                                      this.setState({
                                        addressError: "",
                                      });
                                    }
                                  }}
                                />

                                <div style={{ color: "#ff0000" }}>
                                  {this.state.addressError}
                                </div>
                              </div>
                            ) : (
                              <div className="attri-value">
                                {this.state.projectInfo.street}{" "}
                              </div>
                            )}
                          </div>

                          <div className="attri-container">
                            <div className="attri-title">
                              City <span style={{ color: "#ff0000" }}>*</span>
                            </div>
                            {this.state.isEdit ? (
                              <div className="attri-value">
                                <input
                                  required
                                  aria-label="City"
                                  type="text"
                                  className="form-control"
                                  name="city"
                                  value={this.state.projectInfo.city}
                                  maxLength="128"
                                  onChange={(event) => {
                                    this.handleChange(event);
                                    const regex = new RegExp(/^[A-Za-z ]+$/);
                                    if (event.target.value.length == 0) {
                                      this.setState({
                                        cityerror: "This field is required",
                                      });
                                    } else if (
                                      regex.test(event.target.value) === false
                                    ) {
                                      this.setState({
                                        cityerror: "Invalid City",
                                      });
                                    } else {
                                      this.setState({
                                        cityerror: "",
                                      });
                                    }
                                  }}
                                />

                                <div style={{ color: "#ff0000" }}>
                                  {this.state.cityerror}
                                </div>
                              </div>
                            ) : (
                              <div className="attri-value">
                                {this.state.projectInfo.city}{" "}
                              </div>
                            )}
                          </div>

                          <UpdatedProjectField
                            isRequired={true}
                            label="State"
                            name="state"
                            formData={this.state.projectInfo}
                            handleChange={this.handleChange}
                            isEdit={this.state.isEdit}
                            isError={this.state.stateError}
                            dropdown={true}
                            dropdownValues={this.state.states}
                          />

                          <div className="attri-container">
                            <div className="attri-title">
                              Zip <span style={{ color: "#ff0000" }}>*</span>
                            </div>
                            {this.state.isEdit ? (
                              <div className="attri-value">
                                <input
                                  required
                                  aria-label="Zip Code"
                                  type="text"
                                  className="form-control"
                                  name="zip5"
                                  value={this.state.projectInfo.zip5}
                                  maxLength="5"
                                  onChange={(event) => {
                                    this.handleChange(event);
                                    const regexZip = new RegExp(/^[0-9]{5}$/);
                                    console.log(
                                      regexZip.test(event.target.value)
                                    );
                                    if (event.target.value.length == 0) {
                                      this.setState({
                                        zipError: "This field is required",
                                      });
                                    } else if (
                                      regexZip.test(event.target.value) ===
                                      false
                                    ) {
                                      this.setState({
                                        zipError: "Invalid Zip Code",
                                      });
                                    } else {
                                      this.setState({
                                        zipError: "",
                                      });
                                    }
                                  }}
                                />

                                <div style={{ color: "#ff0000" }}>
                                  {this.state.zipError}
                                </div>
                              </div>
                            ) : (
                              <div className="attri-value">
                                {this.state.projectInfo.zip5}{" "}
                              </div>
                            )}
                          </div>

                          <div className="attri-container">
                            <div className="attri-title">
                              Total Units{" "}
                              <span style={{ color: "#ff0000" }}>*</span>
                            </div>
                            {this.state.isEdit ? (
                              <div className="attri-value">
                                <input
                                  required
                                  aria-label="Total Units"
                                  type="text"
                                  className="form-control"
                                  name="unitCount"
                                  min="0"
                                  maxLength="64"
                                  value={this.state.projectInfo.unitCount}
                                  onChange={(event) => {
                                    this.handleChange(event);
                                    const regex = new RegExp(/^[0-9]*$/);
                                    if (event.target.value.length == 0) {
                                      this.setState({
                                        totalUnitError:
                                          "This field is required",
                                      });
                                    } else if (event.target.value <= 0) {
                                      this.setState({
                                        totalUnitError:
                                          "Number of units cannot be 0 or negative",
                                      });
                                    } else if (
                                      regex.test(event.target.value) === false
                                    ) {
                                      this.setState({
                                        totalUnitError: "Invalid Unit Number",
                                      });
                                    } else {
                                      this.setState({
                                        totalUnitError: "",
                                      });
                                    }
                                  }}
                                />
                                <div style={{ color: "#ff0000" }}>
                                  {this.state.totalUnitError
                                    ? this.state.totalUnitError
                                    : null}
                                </div>
                              </div>
                            ) : (
                              <div className="attri-value">
                                {this.state.projectInfo.unitCount}
                              </div>
                            )}
                          </div>
                          <UpdatedProjectField
                            label="Project Style"
                            name="projectStyle"
                            formData={this.state.projectInfo}
                            handleChange={this.handleChange}
                            isEdit={this.state.isEdit}
                            dropdown={true}
                            dropdownValues={this.state.styles}
                          />
                          {this.state.projectInfo.projectStyle === "Other" ? (
                            <div className="attri-container">
                              <div className="attri-title">
                                Style Comments{" "}
                                <span style={{ color: "#ff0000" }}>*</span>
                              </div>
                              {this.state.isEdit ? (
                                <div className="attri-value">
                                  <input
                                    required
                                    aria-label="Project Style Other Comments"
                                    type="text"
                                    className="form-control"
                                    name="styleComments"
                                    value={this.state.projectInfo.styleComments}
                                    maxLength="200"
                                    onChange={(event) => {
                                      this.handleChange(event);
                                      if (event.target.value.length == 0) {
                                        this.setState({
                                          otherCommentError:
                                            "This field is required",
                                        });
                                      } else {
                                        this.setState({
                                          otherCommentError: "",
                                        });
                                      }
                                    }}
                                  />

                                  <div style={{ color: "#ff0000" }}>
                                    {this.state.otherCommentError}
                                  </div>
                                </div>
                              ) : (
                                <div className="attri-value">
                                  {this.state.projectInfo.styleComments}{" "}
                                </div>
                              )}
                            </div>
                          ) : (
                            ""
                          )}

                          <div className="attri-container">
                            <div className="attri-title">HOA Name</div>
                            {this.state.isEdit ? (
                              <div className="attri-value">
                                <input
                                  aria-label="HOA Name"
                                  type="text"
                                  className="form-control"
                                  name="hoaName"
                                  value={this.state.projectInfo.hoaName}
                                  maxLength="256"
                                  onChange={(event) => {
                                    this.handleChange(event);
                                  }}
                                />
                              </div>
                            ) : (
                              <div className="attri-value">
                                {this.state.projectInfo.hoaName}{" "}
                              </div>
                            )}
                          </div>

                          <div className="attri-container">
                            <div className="attri-title">HOA Tax ID</div>
                            {this.state.isEdit ? (
                              <div className="attri-value">
                                <input
                                  aria-label="HOA Tax ID"
                                  type="text"
                                  className="form-control"
                                  name="hoaTaxId"
                                  value={this.state.projectInfo.hoaTaxId}
                                  maxLength="10"
                                  onChange={(event) => {
                                    this.handleChange(event);
                                    const regex = new RegExp(
                                      /^(\d{2})[-]?\d{7}$/
                                    );
                                    if (event.target.value.length == 0) {
                                      this.setState({
                                        hoaError: "",
                                      });
                                    } else if (
                                      regex.test(event.target.value) === false
                                    ) {
                                      this.setState({
                                        hoaError:
                                          "Invalid Tax ID (format XX-XXXXXXX)",
                                      });
                                    } else {
                                      this.setState({
                                        hoaError: "",
                                      });
                                    }
                                  }}
                                />
                                <div style={{ color: "#ff0000" }}>
                                  {this.state.hoaError}
                                </div>
                              </div>
                            ) : (
                              <div className="attri-value">
                                {this.state.projectInfo.hoaTaxId}{" "}
                              </div>
                            )}
                          </div>
                          <div className="attributes-title-select">
                            <UpdatedProjectField
                              isRequired={true}
                              label="Is the project a cooperative?"
                              name="coopInd"
                              formData={this.state.projectInfo}
                              handleChange={this.handleChange}
                              isEdit={this.state.isEdit}
                              dropdown={true}
                              dropdownValues={this.state.yesNO}
                            />
                          </div>
                          <div className="attributes-title-select">
                            <UpdatedProjectField
                              isRequired={true}
                              label="Does the project consist of manufactured housing?"
                              name="manfdHousngInd"
                              formData={this.state.projectInfo}
                              handleChange={this.handleChange}
                              isEdit={this.state.isEdit}
                              dropdown={true}
                              dropdownValues={this.state.yesNO}
                            />
                          </div>
                          <div className="attributes-title-select">
                            <UpdatedProjectField
                              isRequired={true}
                              label="Does the project consist of attached units?"
                              name="unitsType"
                              formData={this.state.projectInfo}
                              handleChange={this.handleChange}
                              isEdit={this.state.isEdit}
                              dropdown={true}
                              dropdownValues={this.state.unitType}
                            />
                          </div>
                        </div>
                        <div className="restrictions">
                          {typeof this.state.projectRestrictions !==
                            "undefined" ? (
                            <>
                              {typeof this.state.projectRestrictions
                                .projectDR !== "undefined" &&
                                this.state.projectRestrictions.projectDR.length >
                                0 ? (
                                <div className="dlvy-restrictions">
                                  <div className="restrictions-red-parent">
                                    <div className="restrictions-title">
                                      <img
                                        alt="Critical Warning Icon"
                                        className="restrictions-image"
                                        src={CriticalIcon}
                                      />
                                      Delivery Restrictions{" "}
                                      {isProjectStandardRole() ? (
                                        <>
                                          {this.state.projectRestrictions
                                            .showAddManualRestrictions ===
                                            true ? (
                                            <div
                                              style={{
                                                float: "right",
                                                cursor: "pointer",
                                                paddingRight: "11.5px",
                                              }}
                                              onClick={() =>
                                                this.hideComponent(
                                                  "showHideInfo"
                                                )
                                              }
                                            >
                                              <FaPen
                                                style={{
                                                  color: "#1176b5",
                                                  width: "16",
                                                  height: "16",
                                                }}
                                                className="edit-profile-icon"
                                              />
                                            </div>
                                          ) : (
                                            <div
                                              style={{
                                                float: "right",
                                                cursor: "pointer",
                                                paddingRight: "11.5px",
                                              }}
                                            >
                                              <CustomTooltip
                                                style={{
                                                  backgroundColor: "white",
                                                }}
                                                title={this.renderTooltipTitle()}
                                                placement="left"
                                                arrow
                                              >
                                                <span
                                                  id="request-phase"
                                                  style={{
                                                    cursor: "pointer",
                                                    color: "#1c6fa3",
                                                    fontSize: 14,
                                                  }}
                                                >
                                                  <InfoIcon
                                                    style={{
                                                      width: 20,
                                                      height: 18,
                                                      marginBottom: "3px",
                                                    }}
                                                  />
                                                </span>
                                              </CustomTooltip>
                                            </div>
                                          )}
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                    <div className="project-phase-restrictions">
                                      {this.renderDecision2()}
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div className="dlvy-restrictions"></div>
                              )}
                            </>
                          ) : (
                            ""
                          )}
                          {!isTPORole() && typeof this.state.approvalConditions &&
                            this.state.approvalConditions?.projectCondnList ? (
                            <>
                              {this.state.approvalConditions.projectCondnList
                                .length > 0 ? (
                                <ApprovalConditions
                                  projectlist={
                                    this.state.approvalConditions
                                      .projectCondnList
                                  }
                                ></ApprovalConditions>
                              ) : (
                                <div className="conditions-restrictions"></div>
                              )}
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </>
                    )}
                    {showHideRestrictions && (
                      <>
                        {this.state.apiErrRestrictions
                          ? this.state.apiErrRestrictions
                          : null}

                        <div className="bigbutton">
                          <div className="add-restrictions-button">
                            <img
                              alt="Critical Warning Icon"
                              className="button-image"
                              src={CriticalIcon}
                              style={{ paddingBottom: "4px" }}
                            />
                            Add Restrictions{" "}
                            <div style={{ float: "right", display: "flex" }}>
                              <div>
                                <Button
                                  style={{ textTransform: "none", top: "-7px" }}
                                  onClick={() =>
                                    this.hideComponent("showHideInfo")
                                  }
                                >
                                  Cancel
                                </Button>
                              </div>
                              <div>
                                <Button
                                  style={{
                                    color: "#FFFFFF",
                                    backgroundColor: "#1176B5",
                                    textTransform: "none",
                                    top: "-7px",
                                  }}
                                  onClick={this.handleSaveRestrictions}
                                >
                                  Save
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <Grid
                          item
                          xl
                          style={{
                            gridArea: "attributes3",
                            margin: "0px 40px",
                          }}
                        >
                          <div
                            className="btn-tab-container"
                            style={{ borderTop: 0, marginTop: 0 }}
                          >
                            <Grid
                              item
                              xl
                              className="condition-container"
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <div className="custom-restriction">
                                <Button
                                  onClick={handleAddShow}
                                  className="button"
                                  style={{
                                    textTransform: "none",
                                    outline: "auto",
                                    height: "24px",
                                    padding: "0px",
                                    marginBottom: "20px",
                                    paddingRight: "5px",
                                  }}
                                >
                                  <Add
                                    style={{
                                      color: "rgb(17,118,181)",
                                    }}
                                  />{" "}
                                  Create Custom Restriction
                                </Button>
                              </div>
                            </Grid>
                            <div
                              className="tab-view-container"
                              style={{ marginTop: "-49px" }}
                            >
                              <Button
                                className={
                                  this.state.activeTab == "pre"
                                    ? "tab-title active-tab"
                                    : "tab-title"
                                }
                                onClick={() => changeTab("pre")}
                              >
                                Pre-canned (
                                {typeof this.state.formData.preCannedDR !==
                                  "undefined"
                                  ? this.state.formData.preCannedDR.length
                                  : ""}
                                )
                              </Button>
                              <Button
                                className={
                                  this.state.activeTab == "add"
                                    ? "tab-title active-tab"
                                    : "tab-title"
                                }
                                onClick={() => changeTab("add")}
                              >
                                Custom (
                                {typeof this.state.formData.customDR !==
                                  "undefined"
                                  ? this.state.formData.customDR.length +
                                  addedRestrictions.length
                                  : addedRestrictions.length}
                                )
                              </Button>
                            </div>
                          </div>
                          <div style={{ height: "200px", overflowY: "auto" }}>
                            {this.state.activeTab == "pre" ? (
                              <ProjectPreRestrictions
                                formData={this.state.formData}
                                name="preCannedDR"
                                setRestrictionsData={setRestrictionsData}
                                isPre
                              />
                            ) : (
                              <>
                                <ProjectPreRestrictions
                                  formData={this.state.formData}
                                  name="customDR"
                                  setRestrictionsData={setRestrictionsData}
                                  isPre
                                />
                                <ProjectPreRestrictions
                                  formData={this.state.formData}
                                  name="addedRestrictions"
                                  setRestrictionsData={setRestrictionsData}
                                />
                              </>
                            )}
                          </div>
                          <div className="mendatory-sign">
                            {this.state.restrictionErr}
                          </div>

                          <ProjectPreRestrictionsModal
                            showAdd={this.state.showAdd}
                            handleAddClose={handleAddClose}
                            handleAddClick={handleAddClick}
                            formData={this.state.formData}
                          />
                        </Grid>
                      </>
                    )}
                  </div>
                )}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <Accordion defaultActiveKey="1">
          <a class="anchor" id="section1"></a>
          <Card className="accordion-header-card">
            <Card.Header className="accordion-header-space">
              <ContextAwareToggle eventKey={this.state.collapseKey}>
                <p className="accordion-header-title">
                  {" "}
                  Fannie Mae Activity Feed
                </p>
              </ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={this.state.collapseKey}>
              <Card.Body className="accordion-body-card">
                {this.renderFmActivity()}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        {isProjectRole() ? (
          <Accordion defaultActiveKey="1">
            <a class="anchor" id="section2"></a>
            <Card className="accordion-header-card">
              <Card.Header className="accordion-header-space">
                <ContextAwareToggle eventKey={this.state.collapseKey}>
                  <p className="accordion-header-title">PERS</p>
                </ContextAwareToggle>
                {!isReadonlyRole() ? (
                  this.state.newProjectType === "New Condo" ? (
                    <Link to={`/add-new-pers/${this.state.projectId}`}>
                      <button
                        className="add-phase-button"
                        onClick={this.handleClick}
                      >
                        <Add className="edit-profile-icon" />
                        {/* <Add
                          style={{ marginRight: "5px", marginBottom: "4px" }}
                        />{" "} */}
                        Add PERS
                      </button>
                    </Link>
                  ) : this.state.newProjectType === "Established Condo" ? (
                    <Link to={`/add-established-pers/${this.state.projectId}`}>
                      <button
                        className="add-phase-button"
                        onClick={this.handleClick}
                      >
                        <Add className="edit-profile-icon" /> Add PERS
                      </button>
                    </Link>
                  ) : null
                ) : null}
              </Card.Header>
              <Accordion.Collapse eventKey={this.state.collapseKey}>
                <Card.Body className="accordion-body-card">
                  {this.renderPers()}
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        ) : (
          <></>
        )}

        <Accordion defaultActiveKey="1">
          <a class="anchor" id="section3"></a>
          <Card className="accordion-header-card">
            <Card.Header className="accordion-header-space">
              <ContextAwareToggle eventKey={this.state.collapseKey}>
                <p className="accordion-header-title">Phases</p>
              </ContextAwareToggle>
              {isProjectStandardRole() ? (
                this.state.newProjectType === "New Condo" ? (
                  <Link to={`/addNewPhase/${this.state.projectId}`}>
                    <button
                      className="add-phase-button"
                      onClick={this.handleClick}
                    >
                      <Add className="edit-profile-icon" />
                      Add New Phase
                    </button>
                  </Link>
                ) : this.state.newProjectType === "Established Condo" ? (
                  <div></div>
                ) : null
              ) : null}
              <div
                style={{
                  float: "right",
                  marginRight: "1%",
                  paddingTop: "15px",
                }}
              >
                {this.state.newProjectType === "New Condo" && (isLenderRole() || isTPORole()) ? (
                  <CustomTooltip
                    style={{ backgroundColor: "white", zIndex: 1 }}
                    title={this.renderPhaseTooltip()}
                    placement="left"
                    arrow
                    disableFocusListener
                    disableTouchListener
                    interactive
                  >
                    <span
                      id="request-phase"
                      style={{
                        cursor: "pointer",
                        color: "#0D6DA9",
                        fontSize: 14,
                      }}
                    >
                      <InfoIcon
                        style={{ width: 20, height: 18, marginBottom: "3px" }}
                      />{" "}
                      Need to request a new phase?
                    </span>
                  </CustomTooltip>
                ) : (
                  ""
                )}
              </div>
            </Card.Header>
            <Accordion.Collapse eventKey={this.state.collapseKey}>
              <Card.Body className="accordion-body-card">
                {this.renderPhase()}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        {isProjectRole() ? (
          <Accordion defaultActiveKey="1">
            <a class="anchor" id="section4"></a>
            <Card className="accordion-header-card">
              <Card.Header className="accordion-header-space">
                <ContextAwareToggle eventKey={this.state.collapseKey}>
                  <p id="pdfsHeader" className="accordion-header-title">Attachments</p>
                </ContextAwareToggle>
                <DropzoneModal
                  projectvalue={this.state.projectId}
                  phasevalue={this.state.phaseId}
                  persvalue={this.state.persId}
                  projectType={this.state.project.projectType}
                />
              </Card.Header>
              <Accordion.Collapse eventKey={this.state.collapseKey}>
                <Card.Body className="accordion-body-card">
                  {this.renderPdf()}
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        ) : (
          <></>
        )}
        {isProjectRole() || isLenderRole() || isTPORole() || isAdminRole() ? (
          <Accordion defaultActiveKey="1">
            <a class="anchor" id="section5"></a>
            <Card className="accordion-header-card">
              <Card.Header className="accordion-header-space">
                <ContextAwareToggle eventKey={this.state.collapseKey}>
                  <p id="commentsHeader" className="accordion-header-title">Comments</p>
                </ContextAwareToggle>
                <CommentModal
                  projectvalue={this.state.projectId}
                  phasevalue={this.state.phaseId}
                  persvalue={this.state.persId}
                  projectType={this.state.project.projectType}
                />
              </Card.Header>
              <Accordion.Collapse eventKey={this.state.collapseKey}>
                <>
                  <Card.Body className="accordion-body-card">
                    {this.renderComments()}
                  </Card.Body>
                  {this.state.apiErrComment || this.state.internalErrComment ? (
                    <div
                      style={{
                        textAlign: "right",
                        color: "#ff0000",
                        padding: 15,
                      }}
                    >
                      {this.state.apiErrComment
                        ? this.state.apiErrComment
                        : null}
                      {this.state.internalErrComment
                        ? "Internal server error while processing request, please contact Fannie Mae technology support"
                        : null}
                    </div>
                  ) : (
                    ""
                  )}
                </>
              </Accordion.Collapse>
            </Card>
          </Accordion>) : (
          <></>
        )}
        {isProjectRole() && this.state.getLenders.length !== 0 ? (
          <Accordion defaultActiveKey="1">
            <a class="anchor" id="section6"></a>
            <Card className="accordion-header-card">
              <Card.Header className="accordion-header-space">
                <ContextAwareToggle eventKey={this.state.collapseKey}>
                  <p className="accordion-header-title">Lender Version</p>
                </ContextAwareToggle>
              </Card.Header>
              <Accordion.Collapse eventKey={this.state.collapseKey}>
                <Card.Body className="accordion-body-card">
                  {this.renderLenders()}
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        ) : (
          <></>
        )}
        {(isLenderRole() || isProjectRole() || isTPORole()) &&
          this.state.lenderCertificationList.length !== 0 ? (
          <Accordion defaultActiveKey="1">
            <a class="anchor" id="section7"></a>
            <Card className="accordion-header-card">
              <Card.Header className="accordion-header-space">
                <ContextAwareToggle eventKey={this.state.collapseKey}>
                  <p className="accordion-header-title">Lender Certification</p>
                </ContextAwareToggle>
              </Card.Header>
              <Accordion.Collapse eventKey={this.state.collapseKey}>
                <Card.Body className="accordion-body-card">
                  {this.renderLenderCertification()}
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        ) : (
          <></>
        )}
        <ContainerCModal
          showContainerCModal={this.state.showContainerCModal}
          projectData={() => this.getDropDownStatusVal(this.state.project)}
          setShowConainerCModal={(value) => {
            this.setState({
              showContainerCModal: value,
            });
          }}
          details={this.getProjectDetails(this.state.project)}
          getSavedProjectResponse={this.getSavedProjectResponse}
          reasonCodes={(this.state.reasonCodes || []).filter(r => r.active)}
        />
         <ConfirmModal
          showConfirmModal={this.state.showConfirmModal}
          setShowConfirmModal={(value) => {
            this.setState({
              showConfirmModal: value,
            });
          }}
          getSaveConfirmation={this.getSaveConfirmation}
        />
      </div>
    );
  }
}
export default Project;
