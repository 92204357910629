import { Button } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { AgGridReact } from "ag-grid-react";
import axios from "axios";
import React, { useEffect, useMemo, useRef, useState } from "react";
import ReasonCodeDetails from "../ReasonCodeDetails/ReasonCodeDetails";
import { RowControlsRenderer } from "../shared/components/AgGrid/RowControlsRenderer";
import { getDateFormatter, gridConfig } from "../shared/constants/AgGrid/GridConfig";
import mockData from "./mockData.json";
import './ReasonCodeList.css';

export default function ReasonCodeList(props) {
  const [reasonCodeList, setReasonCodeList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [selectedData, setSelectedData] = useState({});

  useEffect(() => {
    getReasonCodeList(true);
  }, []);

  const getReasonCodeList = async (resetPage) => {
    try {
      let data;

      if (window.location.hostname === "localhost") {
        data = mockData;
      } else {
        const response = await axios.get(
          `${global.REACT_APP_API_ENDPOINT}/reasonCodes/getAllReasonCodes`, 
          {}
        );
        data = response.data;
      }

      setReasonCodeList(data);
      props.setGridData(data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleAddClick = () => {
    setSelectedData({});
    setEdit(false);
    setShowModal(true);
  };

  const handleEditClick = (data) => {
    setSelectedData(data);
    setEdit(true);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setSelectedData({});
    setEdit(false);
    setShowModal(false);
  };

  const handleSave = () => {
    handleModalClose();
    getReasonCodeList(false);
  };

  const validationOnSave = (data, errorData) => {
    let isValid = true;
    [{ key: 'severity', label: 'Severity' }, { key: 'reasonCode', label: 'Reason Code' }].forEach(attr => {
      if (reasonCodeList.filter(ele => ele[attr.key] == data[attr.key]).length == 0) {
        isValid = isValid && true;
      } else if (isEdit && data.reasonId == reasonCodeList.filter(ele => ele[attr.key] == data[attr.key])[0].reasonId) {
        isValid = isValid && true;
      } else {
        errorData[`${attr.key}Err`] = `${attr.label} already exist`;
        isValid = false;
      }
    });
    return isValid;
  };

  const gridRef = useRef(null);
  
  const gridOptions = {
    ...gridConfig,

    columnDefs: [
      { headerName: "Reason Code", field: "reasonCode", maxWidth: 160, },
      { headerName: "Internal Definition", field: "reasonDefinitionInternal", },
      { headerName: "External Definition", field: "reasonDefinitionExternal", },
      { headerName: "Severity", field: "severity", maxWidth: 120, },
      { headerName: "Active", field: "active", cellDataType: "boolean", maxWidth: 100, },
      {
        headerName: "Updated on",
        field: "updatedDate",
        cellDataType: "date",
        filter: "agDateColumnFilter",
        sort: "desc",
        // maxWidth: 190,
      },
      { 
        headerName: "", 
        sortable: false,
        filter: false,
        cellRenderer: RowControlsRenderer, 
        cellRendererParams: {
          canEdit: true,
          onEditClick: handleEditClick,
        },
        maxWidth: 90,
      },
    ],
    
    dataTypeDefinitions: {
      ...gridConfig.dataTypeDefinitions,
      date: getDateFormatter("MM/DD/YYYY hh:mm:ss A"),
    },
  
    // onRowDataUpdated: ({api}) => {
    //   if (gridRef.current.offsetWidth) {
    //     api.sizeColumnsToFit();
    //   }
    // }
  };
  
  useEffect(() => {
    props.setGridOptions(gridOptions);
  }, []);

  return (
    <>
      <div style={{position: "absolute", top: "-50px", right: 0}}>
        <Button
          variant="contained"
          onClick={handleAddClick}
          style={{
            backgroundColor: "rgb(17, 118, 181)",
            textTransform: "none"
          }}
          color="primary"
        >
          <Add />
          Add Reason
        </Button>
      </div>

      {reasonCodeList?.length ? (     
        // <div style={{ clear: "both", height: "100%", width: "100%" }}>
        //   <div style={{ height: "438px", width: "100%", marginBottom: "20px" }}>
        //     <AgGridReact 
        //       {...gridOptions} 
        //       ref={gridRef} 
        //       rowData={reasonCodeList} 
        //     />
        //   </div>
        // </div>
        <></>
      ) : (<p className="error"> No records found. </p>)}

      <ReasonCodeDetails
        show={showModal}
        isEdit={isEdit}
        handleClose={handleModalClose}
        handleSave={handleSave}
        data={selectedData}
        handleValidation={validationOnSave}
      />
    </>
  );
}
