import React, { forwardRef, useEffect, useRef, useState } from "react";
import Tooltip from '@mui/material/Tooltip';
import "./TooltipRenderer.css";

export const CellTooltipRenderer = forwardRef(({value, valueFormatted}, ref) => {
  const [cellContent, setCellContent] = useState(value);
  const [tooltipContent, setTooltipContent] = useState(value);
  const [showTooltip, setShowTooltip] = useState(true);
  const wrapper = useRef(null);

  useEffect(() => {
    setCellContent(
      valueFormatted === null 
        ? value
        : valueFormatted
    );    
  }, [value, valueFormatted]);

  useEffect(() => {
    setTooltipContent(<div className="tooltip-plain">{cellContent}</div>);
  }, [cellContent]);

  useEffect(() => {
    setShowTooltip(
      wrapper.current && (wrapper.current.scrollWidth > wrapper.current.clientWidth)
    );
  }, [
    cellContent, 
    wrapper.current, 
    wrapper.current?.scrollWidth, 
    wrapper.current?.clientWidth
  ]);

  return (
    <div ref={wrapper} style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
      {showTooltip ? (
        <Tooltip title={tooltipContent} placement="left-start">
          <span>{cellContent}</span>
        </Tooltip>
      ) : (
        cellContent
      )}
    </div>
  );
});