import React, { useState } from "react";
import PropTypes from "prop-types";
import { Formik, Form, Field } from "formik";
import { Button } from "@material-ui/core";
import FormStepper from "../FormStepper";
import axios from "axios";
import "../../AddEstablishedPers/AddEstablishedPers.css";
import MultiselectTwoSide from "react-multiselect-two-sides";

export const LendingDetails = ({
  formData,
  setFormData,
  nextStep,
  step,
  projectId,
  persId,
  persType,
  setSelectedPhaseList,
  setSelectedSaved,
  selectedPhaseSaved,
}) => {
  const [decisionLevel, setDecisionLevel] = React.useState("");
  const [isPhases, setIsPhases] = React.useState(false);
  const [phaseAvailList, setPhaseAvailList] = React.useState(false);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // let [persDecisionData, setPersDecisionData] = React.useState("");
  const persDecisionDetails = React.useCallback(() => {
    axios
      .get(
        global.REACT_APP_API_ENDPOINT +
          `/decision/getPersDecisionInitialDetails/` +
          projectId +
          "/" +
          persId
      )
      .then((response) => {
        console.log("getPersDecisionInitialDetails =>>", response);
        const isFirstTimeDecision = response.data.isFirstTimeDecision;
        // console.log("firstTime", isFirstTimeDecision)
        window.sessionStorage.setItem(
          "isFirstTimeDecision",
          isFirstTimeDecision
        );
        if (!formData.decisionLevel) {
          formData.decisionLevel = response.data.decisionLevel;
        }

        formData.availablePhases = [
          ...response.data.selectedPhases,
          ...response.data.availablePhases,
        ];
        formData.restrictions = response.data.restrictions.sort(restrictionComparer);
        formData.addedRestrictions = formData.addedRestrictions
          ? formData.addedRestrictions
          : [];
        formData.perCondtnsRef = response.data.conditions;
        formData.addedConditions = formData.addedConditions
          ? formData.addedConditions
          : [];

        formData.selectedPhase = response.data.selectedPhases;
        formData.dateReceived = response.data.dateReceived;
        formData.lenderName = response.data.lenderName;
        formData.lenderSSNumber = response.data.lenderSSNumber;
        formData.projectName = response.data.projectName;
        formData.projectState = response.data.projectState;
        formData.projectStreet = response.data.projectStreet;
        formData.projectStyle = response.data.projectStyle;
        formData.projectZip = response.data.projectZip;
        formData.requester = response.data.requester;
        formData.requesterEmail = response.data.requesterEmail;

        setPhaseAvailList([
          ...response.data.selectedPhases,
          ...response.data.availablePhases,
        ]);
        // setPersDecisionData(response.data);
        setDecisionLevel(formData.decisionLevel);

        if (formData.phaseIds.length > 0) {
          setSelected(formData.phaseIds);
        } else {
          const selectedList = Object.keys(response.data.selectedPhases).map(
            (key) => {
              return response.data.selectedPhases[key].phaseId;
            }
          );

          setSelected(selectedList);
          setSelectedSaved(selectedList);
          const phaseList = Object.keys(formData.availablePhases).map((key) => {
            return {
              value: formData.availablePhases[key].phaseId,
              name: formData.availablePhases[key].phaseDisplayDet,
              unit: formData.availablePhases[key].phaseUnits,
            };
          });

          let selectedPhaseData = [];
          for (let phase of phaseList) {
            let index = selectedList.indexOf(phase.value);
            if (index >= 0) {
              selectedPhaseData.push(phase);
            }
          }

          setSelectedPhaseList(selectedPhaseData);
        }
        setFormData(formData);
        // getPersDetails();
      })
      .catch((error) => {
        throw Error(error);
      });
  }, []);

  React.useEffect(() => {
    persDecisionDetails();
  }, [persDecisionDetails]);

  const [selectedPhase, setSelected] = React.useState([]);
  const [errSelectedPhase, setErrSelected] = React.useState([]);
  const phaseList = Object.keys(phaseAvailList).map((key) => {
    return {
      value: phaseAvailList[key].phaseId,
      name: phaseAvailList[key].phaseDisplayDet,
      unit: phaseAvailList[key].phaseUnits,
    };
  });

  // The canned restrictions all have names with the pattern "Restriction 1", "Restriction 2", "Restriction 3", etc.
  // We want to sort them numerically instead of alphabetically.
  const restrictionComparer = (rest1, rest2) => {
    const getSortValue = restriction => {
      const restrictionNumber = +(/Restriction (\d+)/.exec(restriction.referenceName)[1]);

      // This check shouldn't be necessary unless the names in the DB are misspelled.
      // It's just defensive programming so we fail gracefully.
      return isNaN(restrictionNumber)
        ? restriction.referenceName
        : restrictionNumber;
    };

    return getSortValue(rest1) > getSortValue(rest2)
      ? 1
      : getSortValue(rest1) < getSortValue(rest2)
      ? -1
      : 0;
  }

  function radioCheck(event) {
    setDecisionLevel(event.target.value);

    if (event.target.value == "Phases") {
      setIsPhases(true);
    } else {
      setIsPhases(false);
    }
  }

  // let selected = [0, 3, 9];

  function handleChange(value) {
    setErrSelected("");
    setSelected(value);
    setSelectedSaved(value);

    let selectedPhaseData = [];
    for (let phase of phaseList) {
      let index = value.indexOf(phase.value);
      if (index >= 0) {
        selectedPhaseData.push(phase);
      }
    }

    setSelectedPhaseList(selectedPhaseData);
  }

  return (
    <>
      <Formik
        initialValues={formData}
        onSubmit={(values) => {
          let data = formData;
          data.decisionLevel = decisionLevel;
          data.phaseIds = selectedPhase;
          setFormData(data);
          setErrSelected("");
          if (decisionLevel == "Phases") {
            if (selectedPhase.length <= 0) {
              setErrSelected("Please select atleast one phase");
            } else {
              nextStep();
            }
          } else {
            console.log("Form Data >>", formData);
            nextStep();
          }
        }}
      >
        {({ values, errors, touched }) => (
          <Form className="parent-form">
            <FormStepper step={step} />
            <div className="field-container">
              <div className="top-title-container">
                <div className="big-title">Add New PERS Decision</div>
                <div className="small-title">For PERS ID: {persId}</div>
              </div>

              <div className="field-lower-container">
                <div className="section-title">
                  Step 1 : Review Project or Phases Selected for this Decision
                </div>

                <>
                  <div id="radio-group" className="attribute-title">
                    Is the PERS providing a decision on the entire project or
                    specific phases?
                  </div>
                  <div
                    role="group"
                    aria-labelledby="radio-group"
                    className="radio-box"
                    style={{
                      flexDirection: "row",
                      marginLeft: 25,
                    }}
                  >
                    {phaseList?.length > 0 ? (
                      <>
                        {" "}
                        <label style={{ marginRight: 20 }}>
                          <Field
                            type="radio"
                            name="decisionLevel"
                            value="Entire project"
                            checked={decisionLevel ? "Entire project" : false}
                            onClick={radioCheck}
                          />
                          <span style={{ marginLeft: 5 }}>Entire Project</span>
                        </label>
                        {persType == "New" ? (
                          <label>
                            <Field
                              type="radio"
                              name="decisionLevel"
                              value="Phases"
                              onClick={radioCheck}
                            />
                            <span style={{ marginLeft: 5 }}>Phases</span>
                          </label>
                        ) : null}
                      </>
                    ) : (
                      <>
                        <label style={{ marginRight: 20 }}>
                          <Field
                            type="radio"
                            name="decisionLevel"
                            value="Entire project"
                            checked={decisionLevel ? "Entire project" : false}
                            onClick={radioCheck}
                          />
                          <span style={{ marginLeft: 5 }}>Entire Project</span>
                        </label>
                      </>
                    )}
                  </div>
                </>
                {isPhases || values.decisionLevel == "Phases" ? (
                  <div className="phase-container">
                    <div className="phase-title">Phases </div>
                    <MultiselectTwoSide
                      options={phaseList}
                      value={
                        selectedPhase.length > 0
                          ? selectedPhase
                          : selectedPhaseSaved
                      }
                      className="msts_theme_example"
                      onChange={handleChange}
                      availableHeader="Available Phases"
                      // availableFooter={`Available: ${availableCount}`}
                      selectedHeader="Selected for this PERS"
                      // selectedFooter={`Selected: ${selectedCount}`}
                      labelKey="name"
                      showControls
                      // searchable
                    />
                    <div
                      style={{
                        color: "#ff0000",
                      }}
                    >
                      {errSelectedPhase}
                    </div>
                  </div>
                ) : null}
                <div className="bottom-button-container">
                  <Button type="submit" className="next-button next-btn-save">
                    Next Section
                  </Button>
                </div>
              </div>
            </div>
            <div className="right-cancel-container">
              {/* <Button className="cancel-pers-button" onClick={handleShow}>
                <CloseIcon />
                Cancel PERS Decision
              </Button>
              <Modal show={show} className="my-modal" onHide={handleClose}>
                <Modal.Header className="the-modal-header" closeButton>
                  <Modal.Title className="the-modal-title">
                    Cancel PERS Decision
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="the-modal-body">
                  All of your progress will be lost. Are you sure you want to
                  cancel?
                </Modal.Body>
                <Modal.Footer className="the-modal-footer">
                  <Button className="go-back-button" onClick={handleClose}>
                    Go Back
                  </Button>
                  <Button className="cancel-modal-button" href={`/`}>
                    Cancel PERS Decision
                  </Button>
                </Modal.Footer>
              </Modal>*/}
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

LendingDetails.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  step: PropTypes.func.isRequired,
};
