import moment from "moment";
import { CellTooltipRenderer } from "../../components/AgGrid/CellTooltipRenderer";
import { ModuleRegistry, AllCommunityModule, TooltipModule, themeQuartz } from "ag-grid-community";
// import { AllEnterpriseModule } from 'ag-grid-enterprise';

ModuleRegistry.registerModules([
  AllCommunityModule,
  TooltipModule,
  // AllEnterpriseModule,
]);

window.addEventListener('error', e => {
  if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
      const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div'
      );
      const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay'
      );
      if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
      }
      if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
      }
  }
});

// By default, if you click the same column header 3 times, ag-grid stops sorting on that column. This
// is not typical behavior for a sortable table, and the user is unlikely to expect it to clear the 
// sort. This handler forces it to skip the "clear sort" step.
function skipClearingSort ({api, columns: [column, newColumn]}) {
  if (newColumn === undefined && column.sort == null) {
    api.applyColumnState({
      state: [{ colId: column.colId, sort: "asc" }],
      defaultState: { sort: null },
    });
  }
};

export function getDateFormatter(format) {
  return {
    baseDataType: "date",
    extendsDataType: "date",
    valueFormatter: ({value}) => {
      return value == null
        ? ""
        : moment(value).format(format);
    },
  };
}

export const gridConfig = {
  theme: themeQuartz.withParams({
    headerBackgroundColor: "rgba(17, 118, 181, 0.05)",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: "14px",
    headerFontWeight: 600,      
    headerTextColor: "#000",
    headerHeight: "57px",
    borderColor: "rgb(224, 224, 224)",
  }),

  defaultColDef: {
    flex: 2,
    minWidth: 100,
    // filter: "agSetColumnFilter",
    filter: true,
    suppressHeaderMenuButton: true,
    cellRenderer: CellTooltipRenderer,
  },
  
  dataTypeDefinitions: {
    date: getDateFormatter("MM/DD/YYYY"),
  },

  pagination: true,
  paginationAutoPageSize: true,
  tooltipShowDelay: 500,

  onSortChanged: skipClearingSort,
};