import { AgGridReact } from "ag-grid-react";
import React, { Component, createRef } from "react";
import { Nav, TabContainer, TabContent, TabPane } from "react-bootstrap";
import AuditTrailList from "../AuditTrailList/AuditTrailList";
import DeleteDuplicate from "../DeleteDuplicate/DeleteDuplicate";
import ReasonCodeList from "../ReasonCodeList/ReasonCodeList";
import SmokeTest from "../SmokeTest/SmokeTest";
import "./Admin.css";

class Admin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: "audit-trail",
      showGrid: true,
      gridOptions: undefined,
      rowData: undefined
    };
  }

  gridRef = createRef(null);

  gridState = {
    "audit-trail": {
      gridOptions: undefined,
      rowData: undefined
    },
    "reason-codes": {
      gridOptions: undefined,
      rowData: undefined,
    },
  };

  onTabSelect(tab) {
    if (!tab) {
      tab = this.state.activeTab;
    }

    this.setState({
      activeTab: tab,
      showGrid: !!this.gridState[tab],
      gridOptions: this.gridState[tab]?.gridOptions,
      rowData: this.gridState[tab]?.rowData,
    });
  }

  setGridOptions(options, tabKey) {
    this.gridState[tabKey].gridOptions = options
    this.onTabSelect();
  }

  setGridData(data, tabKey) {
    this.gridState[tabKey].rowData = data
    this.onTabSelect();
  }

  getAuditTrail() {
    let list = <h1>Loading...</h1>;
    list = <AuditTrailList 
      setGridOptions={options => this.setGridOptions(options, "audit-trail")} 
      setGridData={data => this.setGridData(data, "audit-trail")}
    />;
    return list;
  }

  getDuplicateProjectPage() {
    let list = <h1>Loading...</h1>;
    list = <DeleteDuplicate />;
    return list;
  }

  getReasonCodeList() {
    let list = <h1>Loading...</h1>;
    list = <ReasonCodeList
      setGridOptions={options => this.setGridOptions(options, "reason-codes")} 
      setGridData={data => this.setGridData(data, "reason-codes")} 
    />;
    return list;
  }


  getSmokeTestPane() {
    return <SmokeTest />;
  }

  render() {
    return (
      <div style={{margin: "25px 75px 25px 130px"}}>
        <div className="admins-list-title">Admin</div>
        <div>
          <TabContainer id="admin-list-tab" defaultActiveKey="audit-trail" onSelect={(k) => this.onTabSelect(k)}>
            <Nav variant="pills" className="admin-list-nav">
              <Nav.Item>
                <Nav.Link className="admin-list-link" eventKey="audit-trail">
                  Audit Trail
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="admin-list-link" eventKey="delete-duplicate">
                  Delete Duplicate
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="admin-list-link" eventKey="reason-codes">
                  Reason Codes
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="admin-list-link" eventKey="smoke-test">
                  Smoke Test
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <TabContent className="admins-content">
              <TabPane eventKey="audit-trail">{this.getAuditTrail()}</TabPane>
              <TabPane eventKey="delete-duplicate">{this.getDuplicateProjectPage()}</TabPane>
            </TabContent>
            <TabContent className="admins-content">
              <TabPane eventKey="reason-codes">{this.getReasonCodeList()}</TabPane>
            </TabContent>
            
            <TabContent className="admins-content">
              <TabPane eventKey="smoke-test">{this.getSmokeTestPane()}</TabPane>
            </TabContent>
          </TabContainer>

          {this.state.showGrid ? (
            <div style={{ clear: "both", height: "100%", width: "100%" }}>
              <div style={{ height: "438px", width: "100%", marginBottom: "20px" }}>
                <AgGridReact 
                  {...this.state.gridOptions} 
                  rowData={this.state.rowData} 
                  ref={this.gridRef} 
                />
              </div>
            </div>          
          ) : (<></>)}
        </div>
      </div>
    );
  }
}

export default Admin;
