import React, { useState, useEffect } from "react";
import axios from "axios";
import "./ViewCertification.css";
import { ViewCertificationDetails } from "./ViewCertificationDetails/ViewCertificationDetails";
import { fetchTranslatedRoleMap, fetchUserDetails } from "../../redux/actions/userAction";

export const ViewCertification = (props) => {
  window.onscroll = function () {
    effects();
  };
  function effects() { }
  const [streetInfo, setStreetInfo] = useState({});
  const [docData, setDocData] = useState([]);
  const phaseId = props.match.params.phase_id;
  const projectId = props.match.params.project_id;
  const status = props.match.params.status;
  const [convResult, setConvResult] = useState("");
  const getCertificationDetails = async () => {
    if (phaseId === "proj") {
      try {
        const passData = JSON.parse(sessionStorage.getItem("passData"));
        const lenderId =
          sessionStorage.getItem("lenderId") ||
          passData.sellerServicerCoNo ||
          "12346";
        const { data } = await axios.get(
          `${global.REACT_APP_API_ENDPOINT}/lndrCertification/getLatestLenderCertificationResultsForProject/${lenderId}/${projectId}`
        );
        setDocData({data});
        console.log(docData);
      } catch (err) {
        console.log(err);
      }
    } else {
      try {
        const passData = JSON.parse(sessionStorage.getItem("passData"));
        const lenderId =
          sessionStorage.getItem("lenderId") ||
          passData.sellerServicerCoNo ||
          "12346";
        const { data } = await axios.get(
          `${global.REACT_APP_API_ENDPOINT}/lndrCertification/getLatestLenderCertificationResultsForPhase/${lenderId}/${projectId}/${phaseId}`
        );
        setDocData({data});
        console.log(docData);
        if (data && typeof data.projectPresaleSt !== "undefined") {
          calculatedConveyanceResult(
            data.projectPresaleSt.ppTotalUnits,
            data.projectPresaleSt.ppUnitsConveyed
          );
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const calculatedConveyanceResult = (firstNumber, secondNumber) => {
    if (firstNumber && secondNumber) {
      let calculatedConveyance = (secondNumber / firstNumber) * 100;
      setConvResult(calculatedConveyance);
    }
  };

  const init = async () => {
    if (sessionStorage.getItem("role") && sessionStorage.getItem("passData")) {
      console.log('inside if')
      getPageDetails();
    } else {
      console.log('inside else')
      getUserDetails();
    }
  }

  const getUserDetails = async () => {
    await fetchUserDetails();
    translatedRoleMap();
  }

  const translatedRoleMap = async () => {
    await fetchTranslatedRoleMap();
    getPageDetails();
  }

  const getPageDetails = async () => {
    getStreetDetails();
    getCertificationDetails();
  }

  const getStreetDetails = async () => {
    if (phaseId === "proj") {
      try {
        const { data } = await axios.get(
          `${global.REACT_APP_API_ENDPOINT}/lndrCertification/getProjectInfoForLC/${projectId}`
        );
        setStreetInfo({ data });
        setStreetInfo({
          projectCity: data.city,
          projectState: data.state,
          projectStreet: data.street,
          projectZip: data.zip,
          projectName: data.projectName,
        });
      } catch (err) {
        console.log(err);
      }
    } else {
      try {
        const { data } = await axios.get(
          `${global.REACT_APP_API_ENDPOINT}/lndrCertification/getPhaseInfoForLC/${phaseId}`
        );
        setStreetInfo({ data });
        setStreetInfo({
          phaseCity: data.phaseCity,
          phaseName: data.phaseName,
          phaseState: data.phaseState,
          phaseStreet: data.phaseStreet,
          phaseZip: data.phaseZip,
          projectName: data.projectName,
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    init();
  }, []);



  return (
    <div>
      {typeof docData.data !== "undefined" ? (
        <> <ViewCertificationDetails
          streetInfo={streetInfo}
          docData={docData.data}
          convResult={convResult}
          status={status}
          projectId={projectId}
          phaseId={phaseId}
        /></>) : (<></>)
      }
    </div>
  );
};
