import React, { useState, useEffect } from "react";
import axios from "axios";
import "../Certification.css";
import { Formik, Form, Field } from "formik";
import WarningIcon from "../../../images/icons/Warning@1x.svg";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import SaveIcon from "@material-ui/icons/Save";
import FormStepper from "../stepper";
import {
  Button,
  Radio,
  InputAdornment,
  TextField,
  makeStyles,
  FilledInput,
} from "@material-ui/core";
import { CancelButton } from "../CancelButton";
import ApartmentIcon from "../../../images/apartment_black_24dp@2x.svg";
import LayersBlackIcon from "../../../images/layers_black_24dp@1x.svg";
import { certificationFormatter, page7tMultiSelectOptions } from '../../shared/services/SharedService'

export const Page6 = ({
  formData,
  setFormData,
  streetInfo,
  nextStep,
  prevStep,
  page3,
  convResult,
  midRuleType,
  setMidRuleType,
  step,
  status,
  cancelCertification,
}) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const passData = JSON.parse(sessionStorage.getItem("passData"));
  const minDate = new Date("1776-01-01");
  const lenderId =
    sessionStorage.getItem("lenderId") ||
    passData.sellerServicerCoNo ||
    "12346";

  function saveApi() {
    formData = page7tMultiSelectOptions(formData)
    let requestObject = certificationFormatter(formData);
    requestObject.guideEligibilityAndProjectInfoAndIds.phaseId =
      requestObject.guideEligibilityAndProjectInfoAndIds.phaseId === "proj"
        ? ""
        : requestObject.guideEligibilityAndProjectInfoAndIds.phaseId;
    axios
      .post(
        global.REACT_APP_API_ENDPOINT +
          `/lndrCertification/saveLenderCertificationFormsPhase`,
        requestObject
      )
      .then((response) => {
        if (response.status == 200) {
          //setDocId(response.data);
          setTimeout(() => {
            if (formData.phaseId === "proj") {
              window.location.href = "/project/" + formData.projectId;
            } else {
              window.location.href = "/project/phase/" + formData.phaseId;
            }
          }, 300);
        } else if (response.data && response.data.message) {
          if (response.data.message == "Internal server error") {
            window.location.href = "/error-page";
          } else {
            console.log(response.data.message);
          }
        }
        // setPersId(response.data);
      })
      .catch((error) => {
        console.log("certification phase error", error);
      });
  }

  function validateRadio(value) {
    let error;
    if (!value) {
      error = "Required";
    }
    return error;
  }

  function validateNumNoDec(value) {
    let error;
    if (!value && value !== 0) {
      error = "Required";
    } else if (!/^[0-9]*$/i.test(value)) {
      error = "Invalid Number";
    }
    return error;
  }

  const useStyles = makeStyles((theme) => ({
    input: {
      backgroundColor: "#FCFCFC!important",
      boxShadow:
        "0 0.4px 0.6px 0 rgba(0,0,0,0.37), 0 0.1px 0 0.4px rgba(0,0,0,0.21)",
      borderRadius: "4px",
      height: "3px",
    },
    inputPercent: {
      backgroundColor: "#FCFCFC!important",
      boxShadow: "0 0.4px 0.6px 0 rgba(0,0,0,0.37)",
      borderRadius: "4px",
      height: "3px",
    },
  }));

  const classes = useStyles();

  const saveAndNext = (data) => {
    let values = data;
    // reset subquestions
    if (values.poLeaseholdInd === "No") {
      values.poLeaseComplyInd = "";
      values.poLeaseholdExpDate="";
    }
    if (values.poSnglOwnMoreThanOneInd === "No") {
      values.poUnitsSnglOwn = "";
    }
    setFormData(values);
    nextStep();
  };

  function getRuleType() {
    if (formData.phaseId === "proj") {
      if (convResult < 90) {
        if (
          formData.piAddnPhsOrAnnxInd === "No" &&
          formData.piCntlHOATurnOverInd === "Yes" &&
          formData.piCommnAreasCompltInd === "Yes" &&
          formData.ppUnitsComplete === formData.ppTotalUnits &&
          formData.ppDevOwndUntsRentalHoldBackInd === "Yes" &&
          formData.ppDevOwnedUnits / formData.ppTotalUnits <= 0.2
        ) {
          setMidRuleType("projectEst");
        } else {
          setMidRuleType("projectNew");
        }
      } else {
        if (
          formData.piAddnPhsOrAnnxInd === "No" &&
          formData.piCntlHOATurnOverInd === "Yes" &&
          formData.piCommnAreasCompltInd === "Yes" &&
          formData.ppUnitsComplete === formData.ppTotalUnits
        ) {
          setMidRuleType("projectEst");
        } else {
          setMidRuleType("projectNew");
        }
      }
    } else {
      setMidRuleType("");
    }
  }

  function validateExpirationDate(value) {
    if (!value) {
      return "Required Date";
    }    

    const inputDate = new Date(value);

    if (inputDate.toString() === "Invalid Date") {
      return "Invalid date format";
    }

    const minDate = new Date("1776-01-01");

    if (inputDate < minDate) {
      return "Date cannot be before Jan 1, 1776."
    }

    return undefined;
  }

  useEffect(() => {
    getRuleType();
  }, []);

  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      onSubmit={(values) => {
        saveAndNext(values);
      }}
    >
      {({ values, errors, touched, setFieldValue }) => (
        <Form>
          <div className="certification-container">
            {(convResult < 90 &&
              values.phaseId === "proj" &&
              values.piAddnPhsOrAnnxInd === "No" &&
              values.piCntlHOATurnOverInd === "Yes" &&
              values.piCommnAreasCompltInd === "Yes" &&
              values.ppUnitsComplete === values.ppTotalUnits &&
              values.ppDevOwndUntsRentalHoldBackInd === "Yes" &&
              values.ppDevOwnedUnits / values.ppTotalUnits <= 0.2) ||
            (convResult >= 90 &&
              values.phaseId === "proj" &&
              values.piAddnPhsOrAnnxInd === "No" &&
              values.piCntlHOATurnOverInd === "Yes" &&
              values.piCommnAreasCompltInd === "Yes" &&
              values.ppUnitsComplete === values.ppTotalUnits) ? (
              <FormStepper step={3} type={values.phaseId} class="est" />
            ) : values.phaseId === "proj" ? (
              <FormStepper step={4} type={values.phaseId} class="new" />
            ) : (convResult < 90 &&
                values.piAddnPhsOrAnnxInd === "No" &&
                values.piCntlHOATurnOverInd === "Yes" &&
                values.piCommnAreasCompltInd === "Yes" &&
                values.ppUnitsComplete === values.ppTotalUnits &&
                values.ppDevOwndUntsRentalHoldBackInd === "Yes" &&
                values.ppDevOwnedUnits / values.ppTotalUnits <= 0.2) ||
              (convResult >= 90 &&
                values.piAddnPhsOrAnnxInd === "No" &&
                values.piCntlHOATurnOverInd === "Yes" &&
                values.piCommnAreasCompltInd === "Yes" &&
                values.ppUnitsComplete === values.ppTotalUnits) ? (
              <FormStepper step={4} type={values.phaseId} class="est" />
            ) : (
              <FormStepper step={5} type={values.phaseId} class="new" />
            )}
            <div className="main">
              <CancelButton
                status={status}
                cancelCertification={cancelCertification}
              />
              <div className="title">Certification Eligibility Questions</div>
              <div className="subtitle">
                {values.phaseId === "proj" ? (
                  <>
                    <img
                      alt="Apartment Logo"
                      style={{ paddingRight: "8px", paddingBottom: "4px" }}
                      src={ApartmentIcon}
                    />
                    {streetInfo.projectName}
                  </>
                ) : (
                  <>
                    <img
                      alt="Phase Logo"
                      style={{ paddingRight: "8px", paddingBottom: "4px" }}
                      src={LayersBlackIcon}
                    />
                    {streetInfo.phaseName}
                  </>
                )}
              </div>
              <div className="info">
                <div style={{ display: "flex" }}>
                  <div className="info-container">
                    {values.phaseId === "proj" ? (
                      <>
                        <div className="type">Project ID</div>
                        <div className="attributes">{values.projectId}</div>
                      </>
                    ) : (
                      <>
                        <div className="type">Phase ID</div>
                        <div className="attributes">{values.phaseId}</div>
                      </>
                    )}
                  </div>
                  <div
                    className="info-container"
                    style={{ paddingLeft: "220px" }}
                  >
                    {values.phaseId === "proj" ? (
                      <>
                        <div className="type">Address</div>
                        <div className="attributes">
                          {streetInfo.projectStreet}, {streetInfo.projectCity},{" "}
                          {streetInfo.projectState} {streetInfo.projectZip}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="type">Address</div>
                        <div className="attributes">
                          {streetInfo.phaseStreet}, {streetInfo.phaseCity},{" "}
                          {streetInfo.phaseState} {streetInfo.phaseZip}
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div className="info-container" style={{ paddingTop: "0px" }}>
                    {values.phaseId === "proj" ? (
                      <>
                        <div className="type">Project Name</div>
                        <div className="attributes">
                          {streetInfo.projectName}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="type">Project/Phase Name</div>
                        <div className="attributes">
                          {streetInfo.projectName} / {streetInfo.phaseName}
                        </div>
                      </>
                    )}
                  </div>
                  {values.phaseId === "proj" ? (
                    <div
                      className="info-container"
                      style={{ padding: "0px 0px 0px 134px" }}
                    >
                      <>
                        <div className="type">Project Type</div>
                        <div className="attributes">
                          {status === "est" || status === "Incomplete" ? (
                            <>
                              {midRuleType === "projectNew" ? (
                                <div>
                                  New
                                  <img
                                    src={WarningIcon}
                                    style={{
                                      paddingLeft: "3px",
                                      paddingBottom: "5px",
                                      width: "20px",
                                      height: "20px",
                                    }}
                                    alt="Warning Logo"
                                  />
                                </div>
                              ) : (
                                <div>Established</div>
                              )}
                            </>
                          ) : status === "new" || status === "Incomplete" ? (
                            <>
                              {midRuleType === "projectEst" ? (
                                <div>
                                  Established
                                  <img
                                    src={WarningIcon}
                                    style={{
                                      paddingLeft: "3px",
                                      paddingBottom: "5px",
                                      width: "20px",
                                      height: "20px",
                                    }}
                                    alt="Warning Logo"
                                  />
                                </div>
                              ) : (
                                <div>New</div>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>

              <div className="form">
                <div className="form-header">
                  {values.phaseId === "proj" ? (
                    <>
                      {midRuleType === "projectEst" ? (
                        <div className="subheader">Section 4 OF 7</div>
                      ) : (
                        <div className="subheader">Section 5 OF 8</div>
                      )}
                    </>
                  ) : (
                    <>
                      <div className="subheader">Section 6 OF 9</div>
                    </>
                  )}
                  <div className="title">Project Ownership</div>
                  <div className="subtitle"></div>
                </div>
                <div className="form-body">
                  <div className="question">
                    <div className="number">1.</div>{" "}
                    <div>
                      {(convResult < 90 &&
                        values.piAddnPhsOrAnnxInd === "No" &&
                        values.piCntlHOATurnOverInd === "Yes" &&
                        values.piCommnAreasCompltInd === "Yes" &&
                        values.ppUnitsComplete === values.ppTotalUnits &&
                        values.ppDevOwndUntsRentalHoldBackInd === "Yes" &&
                        values.ppDevOwnedUnits / values.ppTotalUnits <= 0.2) ||
                      (convResult >= 90 &&
                        values.piAddnPhsOrAnnxInd === "No" &&
                        values.piCntlHOATurnOverInd === "Yes" &&
                        values.piCommnAreasCompltInd === "Yes" &&
                        values.ppUnitsComplete === values.ppTotalUnits) ? (
                        <>Do </>
                      ) : (
                        <>Will </>
                      )}
                      the unit owners have the sole ownership interest in, and
                      rights to the use of, the project’s facilities, common
                      elements, and limited common elements (unless two or more HOAs
                      share amenities and have an agreement in place governing
                      such arrangement)?
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div className="answer">
                      <div
                        role="group"
                        aria-labelledby="radio-group"
                        className="r-container"
                      >
                        <label className="label">
                          <Field
                            name="poOwnersHaveSoleOwnshpInd"
                            type="radio"
                            variant="outlined"
                            value="Yes"
                            className="alignment"
                            validate={validateRadio}
                          />
                          Yes
                        </label>
                        <label className="label">
                          <Field
                            name="poOwnersHaveSoleOwnshpInd"
                            type="radio"
                            variant="outlined"
                            value="No"
                            className="alignment"
                            validate={validateRadio}
                          />
                          No
                        </label>
                      </div>
                    </div>
                    {errors.poOwnersHaveSoleOwnshpInd &&
                      touched.poOwnersHaveSoleOwnshpInd && (
                        <div className="form-error">
                          {errors.poOwnersHaveSoleOwnshpInd}
                        </div>
                      )}
                  </div>
                  <div className="question">
                    <div className="number">2.</div>{" "}
                    <div> Is the project built on a leasehold estate?</div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div className="answer">
                      <div
                        role="group"
                        aria-labelledby="radio-group"
                        className="r-container"
                        onChange={(event) => {
                          if (event.target.value === "No") {
                            setFieldValue('poLeaseComplyInd','');
                            setFieldValue('poLeaseholdExpDate','');
                          }
                        }}
                      >
                        <label className="label">
                          <Field
                            name="poLeaseholdInd"
                            type="radio"
                            variant="outlined"
                            value="Yes"
                            className="alignment"
                            validate={validateRadio}
                          />
                          Yes
                        </label>
                        <label className="label">
                          <Field
                            name="poLeaseholdInd"
                            type="radio"
                            variant="outlined"
                            value="No"
                            className="alignment"
                            validate={validateRadio}
                          />
                          No
                        </label>
                      </div>
                    </div>
                    {errors.poLeaseholdInd && touched.poLeaseholdInd && (
                      <div className="form-error">{errors.poLeaseholdInd}</div>
                    )}
                  </div>
                  {values.poLeaseholdInd === "Yes" ? (
                    <>
                      <div className="question">
                        <div
                          className="number"
                          style={{
                            borderLeft: "1px solid rgba(0,0,0,0.4)",
                            borderBottom: "1px solid rgba(0,0,0,0.4)",
                            marginLeft: "37px",
                            marginBottom: "10px",
                          }}
                        ></div>{" "}
                        <div style={{ paddingTop: "10px" }}>
                          Does the leasehold comply with Fannie Mae requirements
                          outlined in{" "}
                          <a
                            target="_blank"
                            href="https://www.fanniemae.com/content/guide/selling/b2/3/03.html"
                          >
                            {" "}
                            B2-3-03
                          </a>
                          ?
                        </div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div className="answer" style={{ paddingLeft: "86px" }}>
                          <div
                            role="group"
                            aria-labelledby="radio-group"
                            className="r-container"
                          >
                            <label className="label">
                              <Field
                                name="poLeaseComplyInd"
                                type="radio"
                                variant="outlined"
                                value="Yes"
                                className="alignment"
                                validate={validateRadio}
                              />
                              Yes
                            </label>
                            <label className="label">
                              <Field
                                name="poLeaseComplyInd"
                                type="radio"
                                variant="outlined"
                                value="No"
                                className="alignment"
                                validate={validateRadio}
                              />
                              No
                            </label>
                          </div>
                        </div>
                        {errors.poLeaseComplyInd &&
                          touched.poLeaseComplyInd && (
                            <div className="form-error">
                              {errors.poLeaseComplyInd}
                            </div>
                          )}
                      </div>
                      <div className="question" >
                        <div
                          className="number"
                          style={{
                            borderLeft: "1px solid rgba(0,0,0,0.4)",
                            borderBottom: "1px solid rgba(0,0,0,0.4)",
                            marginLeft: "37px",
                            marginBottom: "10px",
                          }}
                        ></div>{" "}
                        <div style={{ paddingTop: "10px" }}>
                          Leasehold Expiration Date?{" "}
                        </div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div className="answer" style={{ paddingLeft: "86px" }}>
                          <Field
                            name="poLeaseholdExpDate"
                            as={TextField}
                            type="date"
                            inputProps={{
                              className: classes.input,
                              min: minDate,
                              max: '9999-12-31'
                            }}
                            color="#FCFCFC"
                            variant="outlined"
                            validate={validateExpirationDate}
                            error={
                              touched.poLeaseholdExpDate && errors.poLeaseholdExpDate
                            }
                            helperText={
                              touched.poLeaseholdExpDate && errors.poLeaseholdExpDate
                            }
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  <div className="question">
                    <div className="number">3.</div>{" "}
                    <div>
                      Does a single entity own more units in the project than
                      what is allowed by the{" "}
                      <a
                        target="_blank"
                        href="https://www.fanniemae.com/content/guide/selling/b4/2.2/03.html"
                      >
                        {" "}
                        Selling Guide
                      </a>
                      ?
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div className="answer">
                      <div
                        role="group"
                        aria-labelledby="radio-group"
                        className="r-container"
                      >
                        <label className="label">
                          <Field
                            name="poSnglOwnMoreThanOneInd"
                            type="radio"
                            variant="outlined"
                            value="Yes"
                            className="alignment"
                            validate={validateRadio}
                          />
                          Yes
                        </label>
                        <label className="label">
                          <Field
                            name="poSnglOwnMoreThanOneInd"
                            type="radio"
                            variant="outlined"
                            value="No"
                            className="alignment"
                            validate={validateRadio}
                          />
                          No
                        </label>
                      </div>
                    </div>
                    {errors.poSnglOwnMoreThanOneInd &&
                      touched.poSnglOwnMoreThanOneInd && (
                        <div className="form-error">
                          {errors.poSnglOwnMoreThanOneInd}
                        </div>
                      )}
                  </div>
                  {values.poSnglOwnMoreThanOneInd === "Yes" ? (
                    <>
                      <div className="question">
                        <div
                          className="number"
                          style={{
                            borderLeft: "1px solid rgba(0,0,0,0.4)",
                            borderBottom: "1px solid rgba(0,0,0,0.4)",
                            marginLeft: "37px",
                            marginBottom: "10px",
                          }}
                        ></div>{" "}
                        <div style={{ paddingTop: "10px" }}>
                          How many units does the single-entity own? (For
                          multiple single entities, enter the number for the
                          largest single entity or combine the total units for
                          affiliated single entities)
                        </div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div className="answer" style={{ paddingLeft: "86px" }}>
                          <Field
                            name="poUnitsSnglOwn"
                            as={TextField}
                            inputProps={{
                              className: classes.input,
                            }}
                            color="#FCFCFC"
                            variant="outlined"
                            validate={validateNumNoDec}
                            error={
                              touched.poUnitsSnglOwn && errors.poUnitsSnglOwn
                            }
                            helperText={
                              touched.poUnitsSnglOwn && errors.poUnitsSnglOwn
                            }
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <div
                  className="form-footer"
                  style={{ flexDirection: "row-reverse" }}
                >
                  <div
                    style={{ display: "flex", flexDirection: "row-reverse" }}
                  >
                    <button className="next-button" type="submit">
                      Next Section <ArrowForwardIcon />
                    </button>
                    <button className="save-exit-btn" onClick={saveApi}>
                      <SaveIcon
                        style={{ color: "#0C77BA", paddingRight: "6.55px" }}
                      />
                      Save and exit
                    </button>
                  </div>
                  {values.phaseId === "proj" ? (
                    <>
                      {(convResult < 90 &&
                        values.piAddnPhsOrAnnxInd === "No" &&
                        values.piCntlHOATurnOverInd === "Yes" &&
                        values.piCommnAreasCompltInd === "Yes" &&
                        values.ppUnitsComplete === values.ppTotalUnits &&
                        values.ppDevOwndUntsRentalHoldBackInd === "Yes" &&
                        values.ppDevOwnedUnits / values.ppTotalUnits <= 0.2) ||
                      (convResult >= 90 &&
                        values.piAddnPhsOrAnnxInd === "No" &&
                        values.piCntlHOATurnOverInd === "Yes" &&
                        values.piCommnAreasCompltInd === "Yes" &&
                        values.ppUnitsComplete === values.ppTotalUnits) ? (
                        <>
                          <button
                            className="next-button"
                            type="submit"
                            onClick={() => page3()}
                          >
                            <ArrowBackIcon />
                            Previous Section
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            className="next-button"
                            type="submit"
                            onClick={() => prevStep()}
                          >
                            <ArrowBackIcon />
                            Previous Section
                          </button>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <button
                        className="next-button"
                        type="submit"
                        onClick={() => prevStep()}
                      >
                        <ArrowBackIcon />
                        Previous Section
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};